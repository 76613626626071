import React, { Fragment, useState, useEffect } from "react";
import Appbar from "../Appbar";
import {
  Container,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Row, Col } from "bootstrap-4-react";
const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));
const ProfileInfo = ({ userData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const classes = useStyles();
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);
  return (
    <Row className="profileInfo">
      <Col col="12 col-lg-6 col-xl-4">
        <div className="divContainer">
          <Typography className="textLabel">Name:</Typography>
          <Typography className="textValue">
            {userData.firstname} {userData.lastname}
          </Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">Mobile Number:</Typography>
          <Typography className="textValue">{userData.contact}</Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">Role:</Typography>
          <Typography className="textValue">{userData.role}</Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">City:</Typography>
          <Typography className="textValue">{userData.city}</Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">
            School Name/College Name:
          </Typography>

          <Typography className="textValue">
            {userData.schcollname === "" ? "-" : userData.schcollname}
          </Typography>
        </div>
      </Col>
      <Col col="12 col-lg-6 col-xl-4">
        <div className="divContainer">
          <Typography className="textLabel">Pincode:</Typography>
          <Typography className="textValue">
            {userData.pincode === null || userData.pincode === ""
              ? "-"
              : userData.pincode}
          </Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">Board & Medium:</Typography>
          <Typography className="textValue">
            {userData.board.length === 0 ? "-" : userData.board}
          </Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">Grades Taught/Managed:</Typography>
          <Typography className="textValue">
            {userData.grades.length === 0 ? "-" : userData.grades.join(", ")}
          </Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">Subject Taught:</Typography>
          <Typography className="textValue">
            {userData.subjects.length === 0
              ? "-"
              : userData.subjects.join(", ")}
          </Typography>
        </div>
        <div className="divContainer">
          <Typography className="textLabel">Email Address:</Typography>
          <Typography className="textValue">
            {userData.email === "" ? "-" : userData.email}
          </Typography>
        </div>
      </Col>
    </Row>
  );
};

export default ProfileInfo;
