import React, { Component } from "react";
import { List } from "bootstrap-4-react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import "../style/WebinarSchedule.scss";
import { Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import { apiURL, isLogin, basePath } from "../utils";
import axios from "axios";
import Moment from "moment";
import SanitizedHTML from "react-sanitized-html";
import Sign from "../login/Sign";
import {
  Container,
  DialogContent,
  Dialog,
  IconButton,
} from "@material-ui/core";
import WebinarForm from "../webinar/WebinarForm";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import dateImage from "../images/date.png";
import timeImage from "../images/time.png";
import durationImage from "../images/duration.png";
import priceImage from "../images/price.png";
import Video_dialog from "../Video_dialog";
import Meta from "../meta/Meta";
import Rating from "../components/Rating";
import ReactPlayer from "react-player/lazy";
import {
  ArrowBack,
  Bookmark,
  BookmarkBorder,
  LocationOn,
} from "@material-ui/icons";
import { getID } from "../utils";
import ShareDialog from "../components/ShareDialog";
import ShareNews from "../govt-circular-gr/ShareNews";
import { DateRange } from "@material-ui/icons";
import OtherWebinar from "./OtherWebinar";
const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
  },
});
class Webinar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      eventData: [],
      noData: "",
      points: [],
      speakers: [],
      joinNow: false,
      showComponent: false,
      dialogOpen: false,
      videoUrl: "",
      is_bookmark: false,
      isMobile: false,
      showShareDialog: false,
    };

    this._sign = React.createRef();
    this.getPastWebinar = this.getPastWebinar.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.getPastWebinar();
    this.setState({ isMobile: window.innerWidth <= 1000 });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.webinarId !== this.props.match.params.webinarId
    ) {
      window.scrollTo(0, 0);
      this.getPastWebinar();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getPastWebinar = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      webinar_id: this.props.match.params.webinarId,
      user_id: getID(),
    };
    axios
      .post(apiURL("webinar/get_webinar_by_id"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const eventData = resultData.result;
          const points = JSON.parse(resultData.result.fldt_points);
          const speakers = resultData.result.speaker;
          const is_bookmark = eventData.is_bookmark;
          this.setState({
            eventData,
            points,
            speakers,
            is_bookmark,
          });
        } else {
          this.setState({ noData: "Coming Soon" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // this is for webinar check login to join now button
  checkJoinNow = () => {
    this.checkUserRegister();
    this.getWebinar();
    this.setState({ showComponent: false });
  };

  handleClickOpen = () => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({ open: true });
      //this.getPastWebinar();
    }
  };

  checkJoinNow = () => {
    this.getPastWebinar();
    this.setState({ showComponent: false });
  };
  handleClose = (e) => {
    if (e === true) {
      this.setState({
        showSnackbar: true,
        snackBarMessage:
          "Thank you for getting in touch! We appreciate you contacting us....",
        severityColor: "success",
        snackBarTimer: "3000",
        //open: false
      });
    }
    this.setState({ open: false });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  videoDialog = (e, videoUrl) => {
    // alert(videoUrl);
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({
        dialogOpen: true,
        videoUrl: videoUrl,
      });
    }
  };

  videoDialogClose = () => {
    //alert("close");
    this.setState({
      dialogOpen: false,
      videoUrl: "",
    });
  };
  handleBack = () => {
    this.props.history.push("/past-webinars");
  };
  handleShareClick = () => {
    this.setState({ showShareDialog: true });
  };
  handleCloseShareDialog = () => {
    this.setState({ showShareDialog: false });
  };
  handleAddBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        //alert(resultData.message);
        if (resultData.success === true || resultData.success === 1) {
          this.setState({
            is_bookmark: true,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleRemoveBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            is_bookmark: false,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { classes } = this.props;
    const { showShareDialog, isMobile } = this.state;
    const points = this.state.eventData.fldt_points;
    return (
      <React.Fragment>
        <Meta
          tableName={"webinar"}
          objectId={this.props.match.params.webinarId}
          forPage={"inner_page"}
        />
        {this.state.showComponent ? (
          <Sign
            ref={this._sign}
            pageProp={this.props}
            checkJoinNow={(e) => this.checkJoinNow(e)}
          />
        ) : (
          <div>
            <Appbar />
            <div className="sticky-top sign-light">
              <Navmenu />
            </div>
            <section className="BannerBox TrainingBox pastWebinar">
              <div class="sixteen-nine">
                <div class="content text-center text-white invisible">
                  <h2>Past Webinars</h2>
                  <p className="text-uppercase">
                    Past Webinars TO FEED YOUR BRAIN
                  </p>
                </div>
              </div>
            </section>
            <section className="pastweb">
              <Container maxWidth="lg">
                <h1>{this.state.eventData.fldv_title}</h1>
                <List inline>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                  <List.Item inline></List.Item>
                </List>
                <Row className="d-flex">
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="order-lg-1 order-md-1 order-1"
                  >
                    <h5 className="speakerName">
                      {this.state.eventData.fldv_speaker_name}
                    </h5>
                    <p>{this.state.points.map((point) => point.point)}</p>
                    <div className="fixed-content">
                      <SanitizedHTML
                        allowedAttributes={false}
                        allowedTags={false}
                        html={this.state.eventData.fldv_description}
                      />
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="webVideo order-lg-2 order-md-2 order-2"
                  >
                    <div className="player-wrapper">
                      <ReactPlayer
                        url={this.state.eventData.fldv_video_link}
                        className="react-player"
                        controls="true"
                        width="100%"
                        height="100%"
                        config={{
                          file: {
                            attributes: { autoPlay: false, muted: false },
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg="6"
                    md="12"
                    sm="12"
                    className="order-lg-1 order-md-1 order-1"
                  >
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={dateImage}
                              className="img-fluid"
                              // width="30px"
                            />{" "}
                            :{" "}
                            {Moment(
                              this.state.eventData.fldv_select_date
                            ).format("ddd, MMMM Do, YYYY")}
                          </td>
                          <td>
                            <img
                              src={timeImage}
                              className="img-fluid"
                              // width="30px"
                            />{" "}
                            :{" "}
                            {Moment(this.state.eventData.fldt_time).format(
                              "hh:mmA"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <img
                              src={durationImage}
                              className="img-fluid"
                              // width="30px"
                            />{" "}
                            : {this.state.eventData.fldv_duration} Hours
                          </td>

                          <td>
                            <LocationOn
                              color="primary"
                              // style={{ fontSize: "30" }}
                            />
                            :{" "}
                            {this.state.eventData.fldv_venue_location !== "" &&
                              this.state.eventData.fldv_venue_location}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="buttonPastWebContainer">
              <Container className="mt-5" maxWidth="lg">
                <div className="actionWebinar">
                  {this.state.is_bookmark === true ? (
                    <IconButton
                      className="bookmark"
                      style={{
                        color: "orange",
                        border: "3px solid #064e89",
                      }}
                      onClick={() =>
                        this.handleRemoveBookMark(this.state.eventData.fldi_id)
                      }
                    >
                      <Bookmark />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="bookmark"
                      style={{ border: "3px solid #064e89" }}
                      onClick={() =>
                        this.handleAddBookMark(this.state.eventData.fldi_id)
                      }
                    >
                      <BookmarkBorder />
                    </IconButton>
                  )}

                  <div className="rating">
                    <Rating
                      webinarId={this.state.eventData.fldi_id}
                      userId={getID()}
                      rating={this.state.eventData.rating}
                      color="gold"
                    />
                  </div>

                  {/* </div> */}
                </div>
                <div className="share">
                  <ShareNews
                    webinarId={this.state.eventData.fldi_id}
                    shareUrl={
                      this.state.eventData.fldv_video_link === ""
                        ? this.state.eventData.fldv_webinar_url
                        : this.state.eventData.fldv_video_link
                    }
                    title={this.state.eventData.fldv_title}
                    date={Moment(this.state.eventData.fldv_select_date).format(
                      "dddd, D MMM, YYYY"
                    )}
                    time={Moment(this.state.eventData.fldt_time).format(
                      "hh:mmA"
                    )}
                  />
                </div>
                <Row>
                  <div className="backButton">
                    <Button
                      // variant="contained"
                      onClick={this.handleBack}
                      // className="back ml-3"
                    >
                      Back
                    </Button>
                  </div>
                </Row>
              </Container>
            </section>
            {this.state.eventData.fldi_id !== "" && (
              <OtherWebinar
                id={this.state.eventData.fldi_id}
                history={this.props.history}
              />
            )}
            <Footer />
            <ShareDialog
              open={showShareDialog}
              onClose={this.handleCloseShareDialog}
              webinarId={this.state.eventData.fldi_id}
              shareUrl={
                this.state.eventData.fldv_video_link === ""
                  ? this.state.eventData.fldv_webinar_url
                  : this.state.eventData.fldv_video_link
              }
              title={this.state.eventData.fldv_title}
              date={Moment(this.state.eventData.fldv_select_date).format(
                "dddd, D MMM, YYYY"
              )}
              time={Moment(this.state.eventData.fldt_time).format("hh:mmA")}
            />
            <Dialog
              open={this.state.open}
              onClose={() => this.handleClose()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.dialogPaper }}
            >
              <h2 className="text-center  headBoxRegister text-uppercase">
                Webinar
              </h2>
              <DialogContent>
                <WebinarForm
                  webinarId={this.props.match.params.webinarId}
                  title={this.state.eventData.fldv_title}
                  formClose={(e) => this.handleClose(e)}
                />
              </DialogContent>
            </Dialog>
            <Snackbar
              open={this.state.showSnackbar === true}
              autoHideDuration={this.state.snackBarTimer}
              onClose={this.snackBarClose}
            >
              <Alert
                onClose={this.snackBarClose}
                severity={this.state.severityColor}
                elevation={6}
                variant="filled"
              >
                {this.state.snackBarMessage}
              </Alert>
            </Snackbar>

            <Video_dialog
              dialogOpen={this.state.dialogOpen}
              videoUrl={this.state.videoUrl}
              onClose={(e) => this.videoDialogClose(e)}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Webinar);
