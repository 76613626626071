import React, { Fragment, useEffect, useState } from "react";
import Appbar from "../../components/Appbar";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import { Row, Col } from "bootstrap-4-react";
import {
  TextField,
  Button,
  Grid,
  InputLabel,
  Input,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Typography,
  IconButton,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Container,
} from "@material-ui/core";
import Footer from "../Footer";
import { ArrowForward } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import "../../style/Profile.scss";
import Navmenu from "../Navmenu";
import { apiURL } from "../../utils";
import axios from "axios";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import EmailVerified from "../../login/EmailVerified";
import { CloseOutlined } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
      width: "100%",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#00aad3",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const initialValues = {
  schoolName: "",
  pincode: "",
  board_Medium: "",
  grades: [],
  subjects: [],
  email: "",
};
const boardAndMedium = [
  "SSC-Marathi",
  "SSC-English",
  "SSC-Hindi",
  "SSC-Urdu",
  "CBSE",
  "ICSE",
  "Others",
];
const options = [
  "Do not Teach",
  "Pre-Primary (Nursery- Senior Kg)",
  "Primary (Grade 1 to Grade 4)",
  "Middle School (Grade 5 to Grade 7)",
  "High School (Grade 8 to Grade 10)",
  "Higher Secondary (Grade 11 to Grade 12)",
  "Others",
];

const subjectList = [
  "Do not Teach",
  "All Subjects",
  "English",
  "Marathi",
  "Hindi",
  "Urdu",
  "Mathematics",
  "Science",
  "Social Sciences",
  "Commerce Faculty",
  "Arts Faculty",
  "Science Faculty",
  "Others",
];

const ProfileUpdate = ({ classes }) => {
  const history = useHistory();
  const [showSnackBar, setShowSnackBar] = useState(true);
  const [authEmail, setAuthEmail] = useState("");
  const [otpBoxOpen, setOtpBoxOpen] = useState(false);
  const [isEmailAdresssVerified, setEmailAddressVerified] = useState(false);
  const [sendOtpString, setSendOtpString] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailVerifiedMsg, setIsEmailVerifiedMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [grades, setSelected] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openSubjectDrop, setSubjectDrop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSkipNow = (e) => {
    e.preventDefault();
    localStorage.setItem("login_status", true);
    const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
    if (redirectAfterLogin) {
      history?.push(redirectAfterLogin);
      localStorage.removeItem('redirectAfterLogin'); // Clear the saved route
    } else {
      history.push("/?utm_source=web&utm_medium=all-sources&utm_id=conversion"); // Default route after login
    }
   
  };
  const check_email = (e) => {
    if (e.target.value) {
      if (
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          e.target.value
        )
      ) {
        const headers = {
          "content-type": "application/json",
          Accept: "application/json",
        };
        const body = { email: e.target.value };
        axios
          .post(apiURL("register/check_email"), body, { headers: headers })
          .then((response) => {
            const resultData = response.data;
            if (resultData.status === true || resultData.status === 1) {
              setOtpBoxOpen(false);
              setEmailError(true);
              setEmailErrorMsg(resultData.message);
            } else {
              setEmailError(false);
              setEmailErrorMsg("");
            }
          });
      } else {
        setOtpBoxOpen(false);
        setEmailError(true);
        setEmailErrorMsg("Please Enter Valid Email Address");
      }
    }
  };

  const sendOTP = (e) => {
    //alert(e.target.value)
    //const number = document.getElementById('number').value;
    //alert(this.state.mobileNumber)
    const inputValue = e.target.value.trim();

    if (
      !isEmailAdresssVerified &&
      inputValue.includes("@") &&
      !emailError &&
      authEmail.length === 0
    ) {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        email: inputValue,
        //"number": this.state.mobileNumber,
      };
      axios
        .post(apiURL("register/send_otp_email"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            setAuthEmail(atob(decodeURIComponent(resultData.result)));
            setOtpBoxOpen(true);
            setSendOtpString(false);
            setEmail(document.getElementById("email").value);

            localStorage.setItem("otp", resultData.result);
          }
        });
    } else if (email.length > 0) {
      setOtpBoxOpen(false);
      setSendOtpString(false);
      setEmail(document.getElementById("email").value);
    } else {
      setSendOtpString(false);
    }
  };
  const otpBoxClose = (event) => {
    if (event === true) {
      setAuthEmail("");
      setOtpBoxOpen(false);
      setEmailAddressVerified(true);
      setSendOtpString(false);
      setIsEmailVerified(false);
      setIsEmailVerifiedMsg("");
    }
    /*else{
        this.setState({
          authMobile: '',
          otpBoxOpen: false,
          contactNumberVerified: false,
          sendOTPString: false
      })
      }*/
  };

  const onClickOTPClose = (event) => {
    if (event === true) {
      setOtpBoxOpen(false);
      setEmailAddressVerified(false);
      setIsEmailVerified(true);
      setIsEmailVerifiedMsg("Please verify email address");
    }
  };
  const changeNumber = (event) => {
    if (event === true) {
      setAuthEmail("");
      setOtpBoxOpen(false);
      setEmailAddressVerified(false);
      setIsEmailVerified(false);
      setIsEmailVerifiedMsg("");
    }
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };
  const handleClose = () => {
    const filteredGrades = grades.filter((grade) => grade !== undefined);
    setSelected(filteredGrades);
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);
  const handleSubjectDropOpen = () => {
    setSubjectDrop(true);
  };

  const handleSubjectDropClose = () => {
    const filteredSubject = subjects.filter((sub) => sub !== undefined);
    setSubject(filteredSubject);
    setSubjectDrop(false);
  };
  const handleDropChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSelected(options.filter((option) => option !== "all"));
    } else {
      const filteredValue = value.filter((val) => val !== undefined);
      setSelected(filteredValue);
    }
  };
  const handleSubjectDropChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSubject(options.filter((option) => option !== "all"));
    } else {
      const filteredValue = value.filter((val) => val !== undefined);
      setSubject(filteredValue);
    }
  };
  const onSubmit = async (values) => {
    setIsSubmitting(true);
    if (emailError || isEmailVerified) {
      return;
    } else if (values.email !== "" && !isEmailAdresssVerified) {
      return;
    } else {
      const userID = JSON.parse(localStorage.getItem("user_detail"));

      const body = {
        fldi_id: userID.fldi_id,
        pincode: values.pincode,
        sch_col: values.schoolName,
        board_medium: values.board_Medium,
        grades_taught: grades,
        subjects_taught: subjects,
        email: values.email,
      };
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      try {
        const response = await axios.post(apiURL("teacher/add_profile"), body, {
          headers: headers,
        });
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          // console.log(resultData.result);
          localStorage.setItem(
            "user_detail",
            JSON.stringify(resultData.result)
          );
          localStorage.setItem("login_status", true);
          setIsSubmitting(false);
          const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
          if (redirectAfterLogin) {
            history?.push(redirectAfterLogin);
            localStorage.removeItem('redirectAfterLogin'); // Clear the saved route
          } else {
            history.replace(
              "/?utm_source=web&utm_medium=all-sources&utm_id=conversion"
            ); // Default route after login
          }
        
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Profile</title>

        <meta charset="utf-8" />
        <link rel="icon" href="/navdisha-favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="Profile" />
        <link rel="canonical" href={window.location.href} />

        <meta name="description" content="Profile" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Appbar />
      <div className="sticky-top sign-light">
        <Navmenu />
      </div>
      <section className="profile">
        <Snackbar
          open={showSnackBar}
          onClose={() => setShowSnackBar(false)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ marginTop: "3rem" }}
        >
          <Alert
            onClose={() => setShowSnackBar(false)}
            severity="success"
            elevation={6}
            variant="filled"
          >
            Registration Successful
          </Alert>
        </Snackbar>
        <Container maxWidth="md" className="profileUpdate">
          <div className="headingUpdate">
            {" "}
            <h2 className="text-align-center">Profile Information</h2>
          </div>
          <div className="skipNow">
            <Button onClick={(e) => handleSkipNow(e)}>Skip</Button>
          </div>

          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleChange, errors, touched }) => (
              <Form id="login" className={classes.root}>
                <Row className="updateProfileRow">
                  <Col col="12 col-lg-6 col-xl-4" className="col">
                    <div className="form-update-row">
                      <Typography className="label">
                        School Name/College Name:
                      </Typography>
                      <TextField
                        className="input-field"
                        name="schoolName"
                        placeholder="School Name/College Name"
                        fullWidth
                        value={values.schoolName}
                        onChange={handleChange}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-update-row">
                      <Typography className="label">Pincode</Typography>
                      <TextField
                        name="pincode"
                        placeholder="Pincode"
                        className="input-field"
                        fullWidth
                        value={values.pincode}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        inputProps={{
                          maxLength: 6,
                          inputMode: "numeric",
                        }}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-update-row">
                      <Typography className="label">Board & Medium</Typography>
                      <TextField
                        select
                        className="input-field"
                        name="board_Medium"
                        placeholder="Board & Medium"
                        fullWidth
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        value={values.board_Medium || ""}
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.6)" }, // Adjust the placeholder color here
                        }}
                      >
                        {boardAndMedium.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      {/* <TextField
                        select
                        className="input-field"
                        placeholder="Board & Medium"
                        name="board_Medium"
                        value={values.board_Medium}
                        onChange={handleChange}
                        variant="filled"
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        // input={<Input id="status" />}
                      >
                        {boardAndMedium.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField> */}
                    </div>
                    <div className="form-update-row">
                      <Typography className="label">
                        Grades Taught/Managed
                      </Typography>
                      <Select
                        className="select-field"
                        placeholder="Your Grades Taught/Managed"
                        InputProps={{ disableUnderline: true }}
                        variant="filled"
                        multiple
                        value={grades}
                        onChange={handleDropChange}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        renderValue={(grades) => {
                          if (grades.length === 0) {
                            return "None";
                          }
                          return grades.join(", ");
                        }}
                        MenuProps={MenuProps}
                        // fullWidth
                      >
                        <MenuItem
                          onClick={handleClose}
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            style={{ marginTop: "0.1rem" }}
                            color="info"
                            size="small"
                          >
                            <CloseOutlined />
                          </Button>
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox
                                style={{ color: "#00aad3" }}
                                checked={grades.indexOf(option) > -1}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className="form-update-row">
                      <Typography className="label">Subjects Taught</Typography>
                      <Select
                        className="select-subject-field"
                        InputProps={{ disableUnderline: true }}
                        variant="filled"
                        name="subjects"
                        fullWidth
                        multiple
                        open={openSubjectDrop}
                        value={subjects}
                        onChange={handleSubjectDropChange}
                        onOpen={handleSubjectDropOpen}
                        onClose={handleSubjectDropClose}
                        renderValue={(subjects) => {
                          if (subjects.length === 0) {
                            return "None";
                          }
                          return subjects.join(", ");
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          onClick={handleSubjectDropClose}
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            style={{ marginTop: "0.1rem" }}
                            color="info"
                            size="small"
                          >
                            <CloseOutlined />
                          </Button>
                        </MenuItem>
                        {subjectList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox
                                style={{ color: "#00aad3" }}
                                checked={subjects.indexOf(option) > -1}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="form-update-row">
                      <Typography className="label">Email Address:</Typography>
                      <TextField
                        fullWidth
                        name="email"
                        id="email"
                        variant="filled"
                        className="input-email-field"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={(e) => {
                          check_email(e);
                          sendOTP(e);
                          // emailValid(e);
                        }}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <Button
                              disabled={authEmail.length === 0}
                              style={{
                                fontSize: 8,
                              }}
                              onClick={() => {
                                setOtpBoxOpen(true);
                                setSendOtpString(false);
                              }}
                            >
                              Enter Otp
                            </Button>
                          ),
                        }}
                        onKeyUp={(e) => check_email(e)}
                        error={
                          (errors.email && touched.email) ||
                          emailError ||
                          isEmailVerified
                        }
                        helperText={
                          errors.number && touched.number
                            ? errors.number
                            : null || emailErrorMsg || isEmailVerifiedMsg
                        }
                      />
                      {sendOtpString &&
                      !isEmailAdresssVerified &&
                      !emailError ? (
                        <div
                          className="w-100 small"
                          style={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          Please Verify your email address on click{" "}
                          <span
                            onClick={(e) => sendOTP(e)}
                            style={{ cursor: "pointer" }}
                          >
                            send OTP
                          </span>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {isEmailAdresssVerified ? (
                        <div
                          className="w-100 small"
                          style={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          Email Address verified successfully
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <div className="button-update-container">
                    <Button type="submit" disabled={isSubmitting || !isEmailAdresssVerified}>
                      {isSubmitting ? <Spinner variant="light" /> : "Save"}
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </section>
      <EmailVerified
        otpBoxOpen={otpBoxOpen}
        otpValue={authEmail}
        onClickOTPClose={(e) => onClickOTPClose(e)}
        otpBoxClose={(e) => otpBoxClose(e)}
        email={email}
        changeNumber={(e) => changeNumber(e)}
      />
      <Footer />
    </Fragment>
  );
};

export default withStyles(styles)(ProfileUpdate);
