import React, { Component } from 'react';   
import "../style/Glimpses.scss";
import {  Container,  Row, Col, Media, BImg, BH5, BH6 } from 'bootstrap-4-react';
import { Button } from '@material-ui/core'; 
import { withStyles } from "@material-ui/core/styles"; 
import { apiURL } from '../utils';    
import axios from 'axios'; 
import Moment from 'moment'; 

const styles = theme => ({
    
});


class BlogInner extends Component {
   constructor(props) {
      super(props);
      this.state = { 
        blogComments: [],
        noData: ''
      }; 
   }
   componentDidMount() { 
      this._isMounted = true; 
      this.blogComments(); 
  }

  componentWillUnmount() {
      this._isMounted = false; 
  }

  blogComments = (values) => { 
    const headers = {
        'content-type': 'application/json',
        'Accept': 'application/json'
    }
    const body = { 
       "blog_id": this.props.BlogId,
    }
    axios.post(apiURL('blog_comment/get_all_blog_comments'),body, {headers: headers})
    .then(response => {
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({ blogComments: resultData.result });
            this.componentDidMount(); 
        } 
    })
    .catch(error => {
        console.log(error)
    })  
}

snackBarClose = (event, reason) => {
 if (reason === 'clickaway') {
     return;
   }
   this.setState({ showSnackbar: false }); 
}

 render() {  
   // const classes = styles();
   const { classes } = this.props;  
   return (
      <React.Fragment>
      <section className="commentBox">
            <Container>
               <Row>
                  <Col sm="12">
                  <h2>{this.state.blogComments.length > 0 && this.state.blogComments.length+" Comments"}</h2>
                  </Col>

                  {this.state.blogComments.map((arrList, index) => {
                      return <Col key={index} sm="12">
                        <Media> 
                            <Media.Body>
                                <div className="d-flex flex-wrap">
                                <BH5>{arrList.fldv_name}</BH5>
                                <BH6> {Moment(arrList.flddt_created_date).format('Do MMM, YYYY')} at {Moment(arrList.flddt_created_date).format('hh:mm')} </BH6>
                               
                                </div>
                                <p>{arrList.fldt_message}</p>
                            </Media.Body>
                        </Media>
                        </Col>  
                  })}

                  {/* <Col sm="12">
                  <Media>
                     <div className="imgBox">
                        <BImg src="images/comment-1.png" className="img-fluid" mr="3"/>
                     </div>
                     <Media.Body>
                        <div className="d-flex flex-wrap">
                           <BH5>Media heading</BH5>
                           <BH6>13 June, 2018 at 07:30</BH6>
                           <Button className="ml-auto text-capitalize" link>Reply</Button>
                        </div>
                        <p> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                     </Media.Body>
                  </Media>
                  </Col>  
                  <Col sm="10" className="ml-auto" >
                  <Media>
                     <div className="imgBox">
                        <BImg src="images/comment-1.png" className="img-fluid" mr="3"/>
                     </div>
                     <Media.Body>
                        <div className="d-flex flex-wrap">
                           <BH5>Media heading</BH5>
                           <BH6>13 June, 2018 at 07:30</BH6>
                           <Button className="ml-auto text-capitalize" link>Reply</Button>
                        </div>
                        <p> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. </p>
                     </Media.Body>
                  </Media>
                  </Col>  
                  <Col sm="12">
                  <Media>
                     <div className="imgBox">
                        <BImg src="images/comment-1.png"  className="img-fluid"  mr="3"/>
                     </div>
                     <Media.Body>
                        <div className="d-flex flex-wrap">
                           <BH5>Media heading</BH5>
                           <BH6>13 June, 2018 at 07:30</BH6>
                           <Button className="ml-auto text-capitalize" link>Reply</Button>
                        </div>
                        <p> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. </p>
                     </Media.Body>
                  </Media>
                  </Col>   */}
               </Row>
            </Container>
         </section>  
      </React.Fragment>
      );
   }
}
export default withStyles(styles) (BlogInner);  