import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { apiURL } from "../utils";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import {
  FormGroup,
  FormControlLabel,
  Snackbar,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const verifyOtpSchema = yup.object().shape({
  otp: yup.number().required("This field is required."),
});

class EmailVerified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
      otp: null,
      newOTP: "",
    };
  }

  componentDidMount() {
    this.setState({
      otp: null,
    });
  }

  onSubmit = (values) => {
    //if(values.otp === this.props.otpValue)
    if (values.otp === atob(decodeURIComponent(localStorage.getItem("otp")))) {
      this.setState({
        snackBarMessage: "OTP verified successfully",
        showSnackbar: true,
        severityColor: "success",
        snackBarTimer: 7000,
      });
      this.props.otpBoxClose(true);
      localStorage.setItem("isEmailVerified", true);
      localStorage.removeItem("otp");
    } else {
      this.setState({
        showSnackbar: true,
        snackBarMessage: "Please enter valid OTP Number.",
        severityColor: "error",
        snackBarTimer: 7000,
        otp: "",
      });
      this.componentDidMount();
      this.props.onClickOTPClose(false);
    }
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  resetOTPBox = (e) => {
    this.setState({
      otp: null,
    });
  };

  resendOTP = (e) => {
    //alert('mobile numer'+ this.props.mobileNumber);
    e.preventDefault();
    e.stopPropagation();
    if (this.props.email.includes("@")) {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        email: this.props.email,
      };
      axios
        .post(apiURL("register/send_otp_email"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              showSnackbar: true,
              snackBarMessage: "New OTP message send to your email",
              severityColor: "success",
              snackBarTimer: 7000,
            });
            localStorage.setItem("otp", resultData.result);
          }
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={this.props.otpBoxOpen}
          onClose={(e) => this.props.onClickOTPClose(true)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="boxMain"
        >
          <DialogTitle id="max-width-dialog-title">
            {" "}
            Enter OTP {/*this.props.otpValue*/}
          </DialogTitle>
          <DialogContent className="dialouge">
            <Formik
              initialValues={{
                otp: "",
              }}
              validationSchema={verifyOtpSchema}
              onSubmit={(values) => {
                this.onSubmit(values);
              }}
            >
              {({ errors, handleChange, touched }) => (
                <Form
                  style={{ display: "flex", padding: "0px 25px 25px 25px" }}
                  id="forgot-password"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="standard-basic"
                        type="text"
                        className="mt-4"
                        name="otp"
                        label="Enter OTP"
                        onChange={handleChange}
                        value={this.state.otp}
                        error={errors.otp && touched.otp}
                        helperText={
                          errors.otp && touched.otp ? errors.otp : null
                        }
                      />

                      <div
                        className="small"
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          float: "left",
                        }}
                      >
                        Verification code sent on this email {this.props.email}{" "}
                      </div>

                      <div
                        className="small"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          float: "right",
                          cursor: "pointer",
                        }}
                        onClick={(e) => this.props.changeNumber(true)}
                      >
                        Change Email
                      </div>
                    </Grid>

                    {/* <Grid item xs={6} sm={6}>  
                                        <Button type="reset" onClick={ (e) => this.resetOTPBox(e) } fullWidth variant="contained" color="warning" className="mt-4 text-capitalize" >Reset</Button>
                                    </Grid>*/}

                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="mt-4 text-capitalize"
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={16} style={{ marginTop: "10px" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.resendOTP(e)}
                      >
                        {" "}
                        Resend OTP{" "}
                      </span>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default EmailVerified;
