import React, { useState, useEffect } from "react";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import { apiURL, isLogin } from "../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";
const styles = {
  dialogPaper: {
    width: "400px",
  },
  title: {
    textAlign: "center",
  },
  ratingContainer: {
    textAlign: "center",
    // border: "2px solid black",
    // borderRadius: "5px",
  },
  star: {
    cursor: "pointer",
    fontSize: "0.8rem",

    marginRight: "3px",
  },
};

const RatingDialog = ({
  open,
  onClose,
  classes,
  videoId,
  webinarId,
  blogId,
  articleId,
  trainId,
  userId,
  rating,
}) => {
  const isRatingSubmmited = rating !== undefined && rating !== 0;

  const [userRating, setUserRating] = useState(0);
  const [isSubmitted, setIsSubmmited] = useState(isRatingSubmmited);
  const [loading, setLoading] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    const parsedRating = parseInt(rating);
    if (!isNaN(parsedRating)) {
      setUserRating(parsedRating);
    } else {
      setUserRating(0); // Reset userRating when rating is empty or invalid
    }
  }, [rating]);

  useEffect(() => {
    setIsSubmmited(isRatingSubmmited);
  }, [isRatingSubmmited]);
  const handleRatingChange = (value) => {
    setUserRating(value);
    if (!isLogin()) {
      setShowComponent(true);
    } else {
      handleSubmit(value);
    }
  };

  const handleSubmit = (value) => {
    setLoading(true);

    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    console.log(videoId, webinarId);
    const body = {
      video_id: videoId,
      user_id: userId,
      webinar_id: webinarId,
      article_id: articleId,
      blog_id: blogId,
      training_id: trainId,
      rating: value,
    };

    axios
      .post(apiURL("Rating_api/add_rating_video_or_webinar"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setUserRating(resultData.rating);
          setIsSubmmited(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.ratingContainer}>
      {showComponent && <Redirect to="/sign" />}
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          onClick={() => handleRatingChange(index)}
          className={classes.star}
        >
          {index <= userRating ? (
            <StarIcon style={{ color: "gold" }} />
          ) : (
            <StarBorderIcon />
          )}
        </span>
      ))}
    </div>
  );
};

export default withStyles(styles)(RatingDialog);
