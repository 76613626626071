import React, { Component } from "react";
import "./style/Glimpses.scss";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPlayer from "react-player/lazy";

const styles = (theme) => ({
  dialogPaper: {
    borderRadius: "0px",
    maxWidth: "48.75rem",
    width: "60rem",
  },
});

class Video_dialog extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      //videoDialogOpen: this.props.dialogOpen,
      //videoUrl: this.props.videoUrl
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes } = this.props;
    console.log(this.props.dialogOpen, this.props.videoUrl);
    return (
      <React.Fragment>
        <Dialog
          open={this.props.dialogOpen}
          onClose={this.props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="boxMain"
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogContent className="dialouge">
            <div className="player-wrapper">
              <ReactPlayer
                url={this.props.videoUrl}
                className="react-player"
                controls="true"
                width="100%"
                height="100%"
                config={{
                  file: { attributes: { autoPlay: false, muted: false } },
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Video_dialog);
