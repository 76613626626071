import React, { useState, useEffect, useCallback } from "react";
import { Alert } from "bootstrap-4-react";
import { Collapse, Card, Row, Col, Container } from "bootstrap-4-react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "../style/Header.scss";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [
  "//placekitten.com/1500/500",
  "//placekitten.com/4000/3000",
  "//placekitten.com/800/1200",
  "//placekitten.com/1500/1500",
];

const imgs = [
  {
    author: "The Lazy Artist Gallery",
    tag: "People",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/1.jpg?raw=true",
  },
  {
    author: "Daria Shevtsova",
    tag: "Food",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/2.jpg?raw=true",
  },
  {
    author: "Kasuma",
    tag: "Animals",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/3.jpg?raw=true",
  },
  {
    author: "Dominika Roseclay",
    tag: "Plants",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/4.jpg?raw=true",
  },
  {
    author: "Scott Webb",
    tag: "Plants",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/5.jpg?raw=true",
  },
  {
    author: "Jeffrey Czum",
    tag: "People",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true",
  },
  {
    author: "Dominika Roseclay",
    tag: "Food",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/7.jpg?raw=true",
  },
  {
    author: "Valeria Boltneva",
    tag: "Animals",
    src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/8.jpg?raw=true",
  },
];

const filters = [
  { name: "People", status: false },
  { name: "Animals", status: false },
  { name: "Plants", status: false },
  { name: "Food", status: false },
];

const Filters = ({ onClickAll, all, onClick, filters }) => (
  <form>
    <ul>
      <li onClick={onClickAll}>
        <input type="checkbox" checked={all} />
        <label htmlFor="all">All</label>
      </li>
      {filters.map((filter, i) => (
        <li key={i} data-index={i} onClick={onClick}>
          <input id={filter.name} type="checkbox" checked={filter.status} />
          <label htmlFor={filter.name}>{filter.name}</label>
        </li>
      ))}
    </ul>
  </form>
);

const Cards = ({ imgs }) => (
  <ul>
    {imgs.map((img, i) => (
      <li key={i}>
        <figure>
          <img src={img.src} alt={img.author} />
          <figcaption>
            <div>{img.author} </div>
            <span>{img.tag}</span>
          </figcaption>
        </figure>
      </li>
    ))}
  </ul>
);

const Header = () => {
  const [state, setState] = useState({
    open: false,
    imgs,
    filters,
    all: true,
    photoIndex: 0,
    isOpen: false,
    images: [
      {
        author: "The Lazy Artist Gallery",
        tag: "People",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/1.jpg?raw=true",
      },
      {
        author: "Daria Shevtsova",
        tag: "Food",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/2.jpg?raw=true",
      },
      {
        author: "Kasuma",
        tag: "Animals",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/3.jpg?raw=true",
      },
      {
        author: "Dominika Roseclay",
        tag: "Plants",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/4.jpg?raw=true",
      },
      {
        author: "Scott Webb",
        tag: "Plants",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/5.jpg?raw=true",
      },
      {
        author: "Jeffrey Czum",
        tag: "People",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true",
      },
      {
        author: "Dominika Roseclay",
        tag: "Food",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/7.jpg?raw=true",
      },
      {
        author: "Valeria Boltneva",
        tag: "Animals",
        src: "https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/8.jpg?raw=true",
      },
    ],
    imageSource: null,
  });

  const handleClickOpen = () => {
    setState((prevState) => ({ ...prevState, open: true }));
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const setFilter = (e) => {
    e.preventDefault();
    const { filters, all } = state;
    const { index } = e.currentTarget.dataset;

    const newFilters = [...filters];
    newFilters[index].status = !newFilters[index].status;

    setState((prevState) => ({
      ...prevState,
      filters: newFilters,
    }));

    updateFilters();
    updateImgs();
  };

  const setAll = () => {
    const newFilters = state.filters.map((filter) => ({ ...filter, status: false }));

    setState((prevState) => ({
      ...prevState,
      all: true,
      filters: newFilters,
    }));
  };

  const updateFilters = () => {
    const allFiltersTrue = state.filters.every((filter) => filter.status === true);
    const allFiltersFalse = state.filters.every((filter) => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      setAll();
    } else {
      setState((prevState) => ({
        ...prevState,
        all: false,
      }));
    }
  };

  const updateImgs = () => {
    const { filters, all } = state;
    let newImgs = [];

    imgs.forEach((img) => {
      filters.forEach((filter) => {
        if (img.tag === filter.name && filter.status === true) {
          newImgs.push(img);
        }
      });
    });

    setState((prevState) => ({
      ...prevState,
      imgs: newImgs,
    }));
  };

  const lightBoxOpen = useCallback((e, imageSource) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isOpen: true, imageSource: imageSource }));
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
              text ever since the 1500s, when an unknown printer took a galley
              of type
            </p>
          </Col>
        </Row>
      </Container>

      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={state.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Filters
          onClickAll={setAll}
          all={state.all}
          onClick={setFilter}
          filters={state.filters}
        />
        {state.all ? (
          <ul>
            {state.images.map((arrImg, index) => (
              <li key={index} onClick={(e) => lightBoxOpen(e, arrImg.src)}>
                <figure>
                  <img src={arrImg.src} alt={arrImg.author} />
                  <figcaption>
                    <div>{arrImg.author} </div>
                    <span>{arrImg.tag}</span>
                  </figcaption>
                </figure>
              </li>
            ))}
          </ul>
        ) : (
          <ul>
            {state.images.map((arrImg, index) => (
              <li key={index} onClick={(e) => lightBoxOpen(e, arrImg.src)}>
                <figure>
                  <img src={arrImg.src} alt={arrImg.author} />
                  <figcaption>
                    <div>{arrImg.author} </div>
                    <span>{arrImg.tag}</span>
                  </figcaption>
                </figure>
              </li>
            ))}
          </ul>
        )}
      </div>

      {state.isOpen && (
        <Lightbox
          mainSrc={state.imageSource}
          nextSrc={images[(state.photoIndex + 1) % images.length]}
          prevSrc={images[(state.photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setState((prevState) => ({ ...prevState, isOpen: false }))}
          onMovePrevRequest={() =>
            setState((prevState) => ({
              ...prevState,
              photoIndex: (prevState.photoIndex + images.length - 1) % images.length,
            }))
          }
          onMoveNextRequest={() =>
            setState((prevState) => ({
              ...prevState,
              photoIndex: (prevState.photoIndex + 1) % images.length,
            }))
          }
        />
      )}

      <button type="button" onClick={(e) => lightBoxOpen(e)}>
        Open Lightbox
      </button>
    </React.Fragment>
  );
};

export default Header;