import React, { Component, createRef } from "react";
import "../style/OtherWeb.scss";
import { Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import axios from "axios";
import Moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

import Meta from "../meta/Meta";
import { ArrowLeft, BookmarkBorder } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
import logoNew from "../images/navdisha.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
});

class OtherTraining extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      pastTrainingsList: [],
      id: "",
      title: "",
      page: 1,
      per: 4,
      selectedItem: 0,
      isMobile: false,
    };
    this.carouselRef = createRef();
    this.getPastTrainings = this.getPastTrainings.bind(this);
  }

  handleClickOpen = (e, id, title) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({ open: true, id: id, title: title });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    this.getPastTrainings();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleReadMore = (e, id, title) => {
    e.preventDefault();
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.props.history.push("/training/" + id + "/" + title);
    }
  };

  getPastTrainings = () => {
    this.setState({ loading: true });
    const { per, page } = this.state;
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "PE",
      user_id: getID(),
    };
    axios
      .post(apiURL("training_schedule/events"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            pastTrainingsList: resultData.result,
          });
        } else {
          this.setState({
            noData: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_training",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const updatedEventsList = this.state.pastTrainingsList.map(
              (event) =>
                event.fldi_id === id ? { ...event, is_bookmark: true } : event
            );
            this.setState({
              pastTrainingsList: updatedEventsList,
            });
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_training",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.pastTrainingsList.map((event) =>
            event.fldi_id === id ? { ...event, is_bookmark: false } : event
          );
          this.setState({
            pastTrainingsList: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem } = this.state;
    const totalItems = this.state.pastTrainingsList.length;
    const itemsPerPage = this.state.isMobile ? 1 : 2;
    const maxIndex = Math.ceil(totalItems / itemsPerPage) - 1;

    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, maxIndex),
    }));
  };

  render() {
    const { classes, id } = this.props;
    const { pastTrainingsList, selectedItem, isMobile } = this.state;
    const stringId = String(id);

    // Slice data based on selectedItem and per
    const filteredData = id
      ? pastTrainingsList.filter((event) => event.fldi_id !== stringId)
      : pastTrainingsList;

    const itemsPerPage = isMobile ? 1 : 2;
    const startIndex = selectedItem * itemsPerPage;
    const displayData = filteredData.slice(
      startIndex,
      startIndex + itemsPerPage
    );

    const isFirstPage = selectedItem === 0;
    const isLastPage = startIndex + itemsPerPage >= filteredData.length;

    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={11} forPage={"main_page"} />

        <div>
          <section className="otherSection">
            <h2 className="text-center">Other Past Trainings</h2>
            <Container maxWidth="lg" className="container">
              <Carousel
                ref={this.carouselRef}
                showThumbs={false}
                infiniteLoop={false}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={0}
                onChange={() => {}}
              >
                <Row className="otherWeb position-relative">
                  {displayData.map((arrList) => (
                    <Col
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing"
                    >
                      <Card>
                        <Link>
                          <Card.Body
                            onClick={(e) =>
                              this.handleReadMore(
                                e,
                                arrList.fldi_id,
                                arrList.fldv_title
                                  .replace(/ /g, "-")
                                  .toLowerCase()
                              )
                            }
                          >
                            <div className="position-relative">
                              {/* <div className="SpeakerBox text-center"> */}
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <div className="Speaker">
                                    <figure>
                                      <div className="d-block imgBox">
                                        <ArticleImageLoader
                                          imageUrl={
                                            arrList.fldv_image_url === ""
                                              ? logoNew
                                              : arrList.fldv_image_url
                                          }
                                          altText={"img"}
                                        />
                                        {/* <img
                                          src={
                                            arrList.fldv_image_url === ""
                                              ? logoNew
                                              : arrList.fldv_image_url
                                          }
                                          className="img-fluid"
                                          alt="img"
                                        /> */}
                                      </div>
                                      <p>{arrList.fldv_speaker_name}</p>
                                    </figure>
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  <table className="table">
                                    <tr>
                                      <th colSpan="2" className="topic">
                                        {arrList.fldv_title}
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr>
                                        <th>Date:</th>
                                        <td>
                                          {Moment(
                                            arrList.fldv_select_date
                                          ).format("ddd, D MMM, YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Time:</th>
                                        <td>
                                          {Moment(arrList.fldt_time).format(
                                            "LT"
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Duration:</th>
                                        <td>{arrList.fldv_duration} hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <div> */}
                                  <div className="action">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className="readmore"
                                      onClick={(e) =>
                                        this.handleReadMore(
                                          e,
                                          arrList.fldi_id,
                                          arrList.fldv_title
                                            .replace(/ /g, "-")
                                            .toLowerCase()
                                        )
                                      }
                                    >
                                      Read More
                                    </Button>
                                    {arrList.is_bookmark ? (
                                      <IconButton
                                        style={{ color: "orange" }}
                                        className="bookmark"
                                        onClick={(e) =>
                                          this.handleRemoveBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <Bookmark />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        className="bookmark"
                                        onClick={(e) =>
                                          this.handleAddBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <BookmarkBorder />
                                      </IconButton>
                                    )}
                                    {/* </div> */}
                                  </div>
                                </Grid>
                                {/* </div> */}
                              </Grid>
                            </div>
                          </Card.Body>
                        </Link>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel>
              <IconButton
                onClick={this.handlePrev}
                className="prevButton"
                disabled={isFirstPage}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={this.handleNext}
                className="nextButton"
                disabled={isLastPage}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OtherTraining);
