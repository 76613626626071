import React, { useState, useEffect } from "react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import "../style/Glimpses.scss";
import { Container, Row, Col, Jumbotron, List } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import ReactPlayer from "react-player/lazy";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded";
import { apiURL, getID } from "../utils";
import SanitizedHTML from "react-sanitized-html";
import axios from "axios";
import Moment from "moment";
import Meta from "../meta/Meta";
import ShareDialog from "../components/ShareDialog";
import Share from "../blog/Share";
import ShareNews from "./ShareNews";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import OtherGr from "./OtherGr";
import TrainingDefaultImageLoader from "../training/TrainingDefaultImage";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: "30rem",
    "@media(max-width: 575px)": {
      height: "16rem",
    },
  },
  button: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },
  iconbutton: {
    fontSize: "1.6rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
});

const NewsSingle = (props) => {
  const [newsData, setNewsData] = useState([]);
  const [noData, setNoData] = useState("");
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [pdfName, setPdfName] = useState(null);
  const [isBookmark, setIsBookmark] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    blogListData();
    setIsMobile(window.innerWidth <= 1000);

    return () => {
      // Cleanup
    };
  }, []);

  useEffect(() => {
    if (props.match.params.newsId !== id) {
      window.scrollTo(0, 0);
      blogListData();
    }
  }, [props.match.params.newsId, id]);

  const handleShareClick = () => {
    setShowShareDialog(true);
  };

  const handleCloseShareDialog = () => {
    setShowShareDialog(false);
  };

  const blogListData = () => {
    const headers = {
      "Content-type": "application/json",
    };

    const { newsId } = props.match.params;
    const userId = getID();

    const body = { id: newsId, user_id: userId };
    axios
      .post(apiURL("news/get_all_news"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true) {
          //console.log(JSON.stringify(resultData.result[0].fldv_title))
          const newsData = resultData.result[0];
          const is_bookmark = newsData.is_bookmark;
          const id = newsData.fldi_id;
          setNewsData(newsData);
          setIsBookmark(is_bookmark);
          setId(id);
          if (newsData.fldv_pdf_url) {
            const urlParts = newsData.fldv_pdf_url.split("/");
            setPdfName(urlParts[urlParts.length - 1]);
          }
        } else {
          setNoData("Coming Soon");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "news",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        //alert(resultData.message);
        if (resultData.success === true || resultData.success === 1) {
          setIsBookmark(true);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "news",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setIsBookmark(false);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = (board) => {
    if (board === "Maharashtra Board") {
      props.history.push("/maharashtra-state-board");
    } else {
      props.history.push("/central-board");
    }
  };

  const { classes } = props;

  return (
    <React.Fragment>
      <Meta
        tableName={"news"}
        objectId={props.match.params.newsId}
        forPage={"inner_page"}
      />
      <Appbar />
      {/* <div className="NavBar   pb-0 sticky-top ">
      <Navmenu />
   </div> */}
      <div className="sticky-top sign-light">
        <Navmenu />
      </div>
      <section className="BannerBox discussionBanner d-none">
        <div className="sixteen-nine">
          <div className="content text-center text-white">
            <h2>News</h2>
            <p>UPCOMING TRAINING SCHEDULE TO FEED YOUR BRAIN</p>
          </div>
        </div>
      </section>
      <section className="grSingle">
        <Container maxWidth="lg">
          <h1>{newsData.fldv_title}</h1>
          <List inline>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
            <List.Item inline></List.Item>
          </List>
          <Row className="d-flex">
            <Col
              lg="6"
              md="12"
              sm="12"
              className="order-lg-1 order-md-1 order-1"
            >
              {/* <h5>{newsData.fldv_speaker_name}</h5> */}
              {/* <h5>{points.map((point) => point.point)}</h5> */}
              <div className="fixed-content">
                <SanitizedHTML
                  allowedAttributes={false}
                  allowedTags={false}
                  html={newsData.fldt_description}
                />
              </div>
            </Col>
            <Col
              lg="6"
              md="12"
              sm="12"
              className="webVideo order-lg-2 order-md-2 order-2"
            >
              <div className="player-wrapper">
              <TrainingDefaultImageLoader
                      imageUrl={newsData.fldv_image_url}
                      className="react-player"
                      />
                {/* <img
                  src={newsData.fldv_image_url}
                  className="react-player"
                  controls="true"
                  width="100%"
                  height="100%"
                /> */}
              </div>
              {/* <Video /> */}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="buttonGRContainer">
        <Container className="mt-5" maxWidth="lg">
          <div className="actionGrSingle">
            {isBookmark === true ? (
              <IconButton
                className="bookmark"
                style={{
                  color: "orange",
                  border: "3px solid #064e89",
                }}
                onClick={() =>
                  handleRemoveBookMark(newsData.fldi_id)
                }
              >
                <Bookmark />
              </IconButton>
            ) : (
              <IconButton
                className="bookmark"
                style={{ border: "3px solid #064e89" }}
                onClick={() =>
                  handleAddBookMark(newsData.fldi_id)
                }
              >
                <BookmarkBorder />
              </IconButton>
            )}

            {newsData.fldv_pdf_url !== "" && (
              <div className="view">
                <Button>
                  <a
                    href={newsData.fldv_pdf_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View/Download
                  </a>
                </Button>
              </div>
            )}
          </div>

          <div className="grShare">
            <ShareNews
              shareUrl={window.location.href}
              title={newsData.fldv_title}
              date={Moment(newsData.fldv_selected_date).format(
                "dddd, D MMM, YYYY"
              )}
            />
          </div>

          <Row className="">
            <div className="grBack">
              <Button
                // variant="contained"
                onClick={() =>
                  handleBack(newsData.fldv_boards)
                }
              // className="back ml-3"
              >
                Back
              </Button>
            </div>
          </Row>
        </Container>
      </section>
      {id !== "" && (
        <OtherGr
          id={id}
          boards={newsData.fldv_boards}
          shareUrl={window.location.href}
          history={props.history}
        />
      )}
      <Footer />
      <ShareDialog
        open={showShareDialog}
        onClose={handleCloseShareDialog}
        shareUrl={window.location.href}
        title={newsData.fldv_title}
        date={Moment(newsData.fldv_selected_date).format(
          "dddd, D MMM, YYYY"
        )}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(NewsSingle);