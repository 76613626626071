import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/Glimpses.scss";
import {  Container,  Row, Col, Jumbotron,  Display4  } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';   
import Typography from '@material-ui/core/Typography';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import axios from 'axios'; 
import { apiURL, getID } from '../utils';   
import SanitizedHTML from 'react-sanitized-html';
import Moment from 'moment'; 
import { Link } from 'react-router-dom';  
import Meta from '../meta/Meta';



const styles = theme => ({
    root: {
        maxWidth: "100%",  
      },
      media: {
        height: "30rem",
      },

});
class ArticleSingle extends Component {
  _isMounted = false; 
  constructor(props) {
    super(props);
    this.state = {
      articleData: [],
      categoryID: null,
      noData: '',
    }
    this.articleListData  = this.articleListData.bind(this);
   
}

  componentDidMount()
  {
    window.scrollTo(0, 0);
    this._isMounted = true;  
    this.articleListData();
  }

  articleListData = () => { 
    const headers = { 
        'Content-type': 'application/json'
    }
    const body = { 
      "id": this.props.match.params.articleId,
      "category_id": ''
    }
    axios.post(apiURL('article/get_all_article'), body, {headers: headers})
    .then(response => {
        
    if(this._isMounted){
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({  
              articleData: resultData.result[0], 
              noData: '',
            });
        }
        else{
            this.setState({noData: "Coming Soon", articleData: []}); 
        }
    }
    })
    .catch(error => {
        console.log(error)
    }) 
  }

  componentWillUnmount() {
    this._isMounted = false; 
  }

render() {  
    // const classes = styles();
    const { classes } = this.props;  
return (
<React.Fragment>
   
    <Meta tableName={"article"} objectId={this.props.match.params.articleId} forPage={"inner_page"} />
   <Appbar />
   {/* <div className="NavBar  pb-0 sticky-top ">
      <Navmenu />
   </div> */}
   <div className="sticky-top sign-light">
      <Navmenu />    
    </div>  
   <section className="BannerBox videoBanner ">
      <div className="sixteen-nine">
         <div className="content text-center text-white d-none">
            <h2>Article</h2>
            <p>UPCOMING TRAINING SCHEDULE TO FEED YOUR BRAIN</p>
         </div>
      </div>
   </section>

   <section className="articleSingle">
       <Container>
           <Row>
               <Col sm="12">
               <Card className={classes.root}>

        <CardMedia
          className="d-none"
          image={this.state.articleData.fldv_image_url}
          title={this.state.articleData.fldv_title}
         
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6" className="d-none">
          {Moment(this.state.articleData.fldv_selected_date).format('D MMMM, YYYY')}
          </Typography>
          <Typography gutterBottom variant="h4" component="h4" className="my-3">
              {this.state.articleData.fldv_title}
          </Typography>
		
          <SanitizedHTML allowedAttributes={false} allowedTags={false} html={this.state.articleData.fldt_description} /> 
		  
          {/*<Typography variant="body2" color="textSecondary" component="p">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
          </Typography>
           <Row>
               <Col sm="6" className="mt-4">
                   <figure>
                       <img src="images/article-single-1.jpg" className="img-fluid" alt="" />  
                   </figure>
               </Col>
               <Col sm="6"  className="mt-4">
                    <figure>
                       <img src="images/article-single-2.jpg" className="img-fluid" alt="" />  
                   </figure>
               </Col>
           </Row>

           <Typography variant="body2" color="textSecondary" component="p">

           Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.   lroemrer
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.   lroemrer
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.   lroemrer
          </Typography>

          <Jumbotron>
            <Row>
              <Col className="position-relative">
                <div className="quoteBox">
                    <FormatQuoteRoundedIcon />
                </div>
              <h3>
                  What sort of men would think it is acceptable to subject a young girl to this level of brutality
                  and violence? an attack like this in ourcommunities and we must all work together.
                  </h3>

                  </Col>    
                  
            </Row>
          </Jumbotron>*/}
             
        </CardContent>
  
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
               </Col>
           </Row>
       </Container>
   </section>
  
   
      <Footer />
   
</React.Fragment>
);
}
}
export default withStyles(styles) (ArticleSingle);