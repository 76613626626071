import React, { useState, useEffect, useRef } from "react";
import "../../style/ArticleNews.scss";
import {
  IconButton,
  Button,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
} from "@material-ui/core";
import { Row, Col, Card } from "bootstrap-4-react";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../../utils";
import Meta from "../../meta/Meta";
import Appbar from "../Appbar";
import Navmenu from "../Navmenu";
import Image from "../../images/newsample.gif";
import NewsDescriptionDialog from "./NewsDescriptionDialog";
import Footer from "../Footer";

const ArticleNews = (props) => {
  const [national, setNational] = useState([]);
  const [maharashtra, setMaharashtra] = useState([]);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");

  const isMounted = useRef(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    getNewsData();

    // This effect runs only once on mount
    return () => {
      isMounted.current = false;
    };
    // this.scrollListener = window.addEventListener("scroll", this.handleScroll);
  }, []);

  useEffect(() => {
    // Cleanup function
    return () => {
      // window.removeEventListener("scroll", this.handleScroll);
    };
  }, []);

  const getNewsData = () => {
    const headers = {
      "Content-type": "application/json",
    };

    axios
      .get(apiURL("article/get_data?category_id=7"), {
        headers: headers,
      })
      .then((response) => {
        if (isMounted.current) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const newsData = resultData.result;
            setNational(newsData.National);
            setMaharashtra(newsData.Maharashtra);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickOpen = (e, title, description, id) => {
    if (!isLogin()) {
      props.history.replace("/sign");
    }
    // else{
    setOpen(true);
    setTitle(title);
    setDescription(description);
    //}
  };

  const handleClose = () => {
    //this.setState({ open: false, videoUrl:'' })
    setOpen(false);
    // setVideoUrl("");
  };

  const { language } = props;

  return (
    <React.Fragment>
      <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

      <div>
        <Appbar />
        <div className="sticky-top sign-light">
          <Navmenu />
        </div>
        <section className="BannerBox newsBanner">
          <div className="sixteen-nine">
            <div className="content text-center text-white d-none">
              <h2>Articles </h2>
              <p>Update</p>
            </div>
          </div>
        </section>
        <section>
          <Container maxWidth="lg">
            <Row className="articleNews">
              <Col lg="6">
                <h2 className="text-center">National</h2>
                <Box
                  height="auto"
                  width="100%"
                  p={3}
                  pt={10}
                  sx={{ borderRadius: "2%" }}
                  style={{ background: "#EEEDEB" }}
                >
                  {national.length !== 0 ? (
                    <React.Fragment>
                      {national.map((newsData) => (
                        <Card
                          key={newsData.id}
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleClickOpen(
                              e,
                              newsData.title,
                              // newsData.fldi_id,
                              newsData.fldv_link
                            )
                          }
                        >
                          {/* <Card.Body> */}
                          <div className="mainInfo">
                            <figure>
                              <div className="d-block mainImage">
                                <img
                                  src={newsData.image}
                                  className="img-fluid"
                                  // alt={arrArticle.fldv_title}
                                />
                              </div>
                            </figure>
                            <div className="content">
                              <div className="topic">
                                <h4>
                                  <span>{newsData.publisher}</span>
                                  <br />
                                  {newsData.title}
                                </h4>
                              </div>
                              <div className="date">
                                {newsData.date !== null &&
                                  newsData.date !== "" && <h4>28/06/2021</h4>}
                              </div>
                            </div>
                          </div>
                          {/* </Card.Body> */}
                        </Card>
                      ))}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </Box>
              </Col>
              <Col lg="6">
                <h2 className="text-center">Maharashtra</h2>
                <Box
                  height="auto"
                  width="100%"
                  p={3}
                  pt={10}
                  sx={{ borderRadius: "2%" }}
                  style={{ background: "#EEEDEB" }}
                >
                  {maharashtra.length !== 0 ? (
                    <React.Fragment>
                      {maharashtra.map((newsData) => (
                        <Card
                          key={newsData.id}
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleClickOpen(
                              e,
                              newsData.title,
                              // newsData.fldi_id,
                              newsData.fldv_link
                            )
                          }
                        >
                          {/* <Card.Body> */}
                          <div className="mainInfo">
                            <figure>
                              <div className="d-block mainImage">
                                <img
                                  src={newsData.image}
                                  className="img-fluid"
                                  // alt={arrArticle.fldv_title}
                                />
                              </div>
                            </figure>
                            <div className="content">
                              <div className="topic">
                                <h4>{newsData.title}</h4>
                              </div>
                              <div className="date">
                                {newsData.date !== null &&
                                  newsData.date !== "" && <h4>28/06/2021</h4>}
                              </div>
                            </div>
                          </div>
                          {/* </Card.Body> */}
                        </Card>
                      ))}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </Box>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
        <NewsDescriptionDialog
          title={title}
          dialogOpen={open}
          description={description}
          onClose={(e) => handleClose(e)}
        />
      </div>
    </React.Fragment>
  );
};

export default ArticleNews;