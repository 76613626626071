import React, { Fragment, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { apiURL, getContact, getEmail, getLastName, getName } from "../utils";
import axios from "axios";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  ListItemText,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "../style/Faq.scss";
import ProfileForm from "../../src/components/TR-For-Teacher/ProfileForm";
import { getID } from "../utils";
import { Spinner } from "react-bootstrap";
const options = [
  "Pre-Primary (Nursery- Senior Kg)",
  "Primary (Grade 1 to Grade 4)",
  "Middle School (Grade 5 to Grade 7)",
  "High School (Grade 8 to Grade 10)",
  "Higher Secondary (Grade 11 to Grade 12)",
];
const subjectList = [
  "General",
  "English",
  "Mathematics",
  "Science",
  "Hindi",
  "Marathi",
  "History",
];

const validationSchema = Yup.object({
  grades: Yup.string().required("Grade is required"),
  subject: Yup.string().required("Subject is required"),
  question: Yup.string().required("Question is required"),
});

const AskMoreQuesDialog = ({ open, handleClose }) => {
  const initialValues = {
    grades: "",
    subject: "",
    question: "",
  };
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    contact: "",
    email: "",

    role: "",
    city: "",
    schcollname: "",
    pincode: "",
    board: "",
    grades: [],
    subjects: [],
  });
  const [profileOpen, setProfileOpen] = useState(false);
  const [isSubmitting,setIsSubmitting]=useState(false)
  useEffect(() => {
    fetchUserProfile();
  }, []);
  const fetchUserProfile = async () => {
    const body = {
      fldi_id: getID(),
    };
    const header = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    try {
      const response = await axios.post(apiURL("teacher/get_profile"), body, {
        headers: header,
      });
      const result = response.data.result;

      const firstname =
        result.fldv_first_name !== ""
          ? result.fldv_first_name
          : result.fldv_name;
      const lastname =
        result.fldv_last_name !== ""
          ? result.fldv_last_name
          : result.fldv_surname;
      const contact = result.fldv_contact;
      const email = result.fldv_email;
      const role = result.fldv_faculty_status;

      const city = result.fldi_city;
      const schcollname = result.fldv_school_college;
      const pincode = result.fldv_pincode;
      const board = result.fldv_board_medium;

      const grades = result.fldv_grades_taught
        ? result.fldv_grades_taught.split(",").map((grade) => grade.trim())
        : [];

      const subjects = result.fldv_subjects_taught
        ? result.fldv_subjects_taught.split(",").map((sub) => sub.trim())
        : [];
      const userProfile = {
        firstname: firstname,
        lastname: lastname,
        contact: contact,
        email: email,

        role: role,
        city: city,
        schcollname: schcollname,
        pincode: pincode,
        board: board,
        grades: grades,
        subjects: subjects,
      };
      setUser(userProfile);
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  };
  const handleOpen = () => {
    setProfileOpen(true);
  };
  const handleProfileClose = (updatedUser) => {
    setProfileOpen(false);
    if (updatedUser) {
      setUser(updatedUser);
      if (validateFields(updatedUser)) {
        document
          .getElementById("askMoreForm")
          .dispatchEvent(new Event("submit"));
      }
    } else {
      fetchUserProfile();
    }
  };
  const validateFields = (user) => {
    return (
      user.firstname !== "" &&
      user.lastname !== "" &&
      user.contact !== "" &&
      user.email !== "" &&
      user.role !== "" &&
      user.city !== "" &&
      user.schcollname !== "" &&
      user.pincode !== "" &&
      user.board !== "" &&
      user.grades.length > 0 &&
      user.subjects.length > 0
    );
  };

  const onSubmit = (values, { resetForm }, userToDownload = user) => {
    if (!validateFields(userToDownload)) {
      handleOpen();
    } else {
      setIsSubmitting(true)
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };

      const body = {
        name: `${getName()} ${getLastName()}`,
        email: getEmail(),
        contact: getContact(),
        topic: "FAQ",
        message: values.question,
      };

      axios
        .post(apiURL("contact_us_enquiry/add"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            resetForm();
            handleClose();
            setIsSubmitting(false)
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched }) => (
              <Form className="askMoreForm" id="askMoreForm">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      select
                      className="input-field"
                      name="grades"
                      placeholder="Your City"
                      label="Grades"
                      fullWidth
                      variant="outlined"
                      required
                      error={touched.grades && !!errors.grades}
                      helperText={touched.grades && errors.grades}
                    >
                      {options.map((option) => (
                        <MenuItem key={option} value={option}>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      select
                      className="input-field"
                      name="subject"
                      label="Subject"
                      fullWidth
                      variant="outlined"
                      error={touched.subject && !!errors.subject}
                      helperText={touched.subject && errors.subject}
                      required
                    >
                      {subjectList.map((option) => (
                        <MenuItem key={option} value={option}>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field
                      as={TextField}
                      name="question"
                      className="input-field question-field"
                      placeholder="Enter Your Question"
                      fullWidth
                      variant="outlined"
                      error={touched.question && !!errors.question}
                      helperText={touched.question && errors.question}
                    />
                  </Grid>
                </Grid>
                <DialogActions className="dialogAction d-flex justify-content-center">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner variant="light" /> : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleProfileClose}
        aria-labelledby="customized-dialog-title"
        open={profileOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleProfileClose}>
          Please Complete Your Profile
        </DialogTitle>
        <DialogContent>
          <ProfileForm
            userData={user}
            open={profileOpen}
            onClose={handleProfileClose}
          />
          {/*</form>*/}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AskMoreQuesDialog;
