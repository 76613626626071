import React, { Component } from "react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import "../style/training.scss";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Link } from "react-router-dom";
import {
  apiURL,
  isLogin,
  getName,
  getEmail,
  getContact,
  getID,
} from "../utils";
import axios from "axios";
import Moment from "moment";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Sign from "../login/Sign";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import Meta from "../meta/Meta";
import Overview1 from "../images/Overviewimage1.jpg";
import Overview2 from "../images/Overviewimage2.jpg";

import Graph from "../images/Graph.jpg";
const styles = (theme) => ({
  dialogPaper: {
    borderRadius: "25px",
    maxWidth: "42.75rem",
  },
  title: {
    margin: theme.spacing(4, 0, 0),
    fontSize: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  title1: {
    // margin: theme.spacing(0, 0, 0),
    fontSize: "1.25rem",
    fontFamily: "gotham rounded medium",
    width: "17rem",
  },
});

class TrainingSchedule extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      upcomeingEventsList: [],
      noData: "",
      registerDialog: false,
      showComponent: false,
      name: getName(),
      email: getEmail(),
      contact: getContact(),
      title: "",
      id: "",
      joinNow: "",
      alreadyRegister: false,
      userId: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
    };
    this.getUpComeinEvents = this.getUpComeinEvents.bind(this);
    this.registerTraining = this.registerTraining.bind(this);
    this.registerTrainingClose = this.registerTrainingClose.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUpComeinEvents();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  getUpComeinEvents = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "UE",
      user_id: isLogin() ? getID() : this.state.userId,
    };
    axios
      .post(apiURL("training_schedule/events"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        //alert(resultData.message);
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ upcomeingEventsList: resultData.result });
        } else {
          this.setState({ noData: "Coming Soon" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  registerTraining = (e, trainingId, title) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.getUpComeinEvents();
      this.setState({ registerDialog: true, id: trainingId, title: title });
    }
  };

  registerTrainingClose = () => {
    this.setState({ registerDialog: false, id: "", title: "" });
  };

  checkJoinNow = () => {
    this.getUpComeinEvents();
    this.setState({
      showComponent: false,
      //registerDialog: true,
      name: getName(),
      email: getEmail(),
      contact: getContact(),
      userId: getID(),
    });
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  registerNow = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      training_id: this.state.id,
      user_id: getID(),
    };
    axios
      .post(apiURL("join_training_user/add"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            joinNow: true,
            registerDialog: false,
            showSnackbar: true,
            snackBarMessage:
              "Thank you for getting in touch! We appreciate you contacting us....",
            severityColor: "success",
            snackBarTimer: "3000",
          });
          this.componentDidMount();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={7} forPage={"main_page"} />
        {this.state.showComponent ? (
          <Sign
            ref={this._sign}
            pageProp={this.props}
            checkJoinNow={(e) => this.checkJoinNow(e)}
          />
        ) : (
          <div>
            <Appbar />
            <div className="sticky-top sign-light">
              <Navmenu />
            </div>
            <section className="BannerBox TrainingBox upcoming">
              <div class="sixteen-nine">
                <div class="content text-center text-white invisible">
                  <h2>Upcoming Trainings Schedule</h2>
                  <p>UPCOMING TRAININGS SCHEDULE TO FEED YOUR BRAIN</p>
                </div>
              </div>
            </section>

            <section className="Overview">
              <Container>
                <h2>Introduction</h2>
                <Row>
                  <Col
                    lg="8"
                    md="12"
                    sm="12"
                    className="order-lg-2 order-md-2 order-1"
                  >
                    {/* Content for the first column */}

                    <p>
                      Navneet Foundation, under its CSR programme, initiated the
                      Teachers' Training Programme in June 2016. At Navneet, we
                      are committed to fostering excellence in education through
                      our comprehensive teacher training programs.{" "}
                    </p>
                    <p>
                      According to NEP-2020, teachers must complete 50 hours of
                      CPD each year. This CPD can be obtained through various
                      formats such as in-person meetings, online courses,
                      workshops, seminars, and other educational activities.
                      Such a government efforts were strengthened by
                      contributions from Navneet Foundation.
                    </p>
                    <p>
                      Our initiative is designed to guide and equip educators
                      with the skills and knowledge they need to excel in their
                      profession. Our Teacher’s Training Program focus on
                      enhancing teaching skills, keeping educators updated on
                      the latest trends in education, and encouraging innovative
                      teaching methods.
                    </p>
                  </Col>
                  <Col lg="4" md="12" sm="12" className="order-lg-2 order-md-1">
                    <div className="image1">
                      <img alt="overview" src={Overview1} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <h5>Our training sessions address several key areas:</h5>
                  <Col
                    lg="8"
                    md="12"
                    sm="12"
                    className="order-lg-2 order-md-2 order-1"
                  >
                    <p>
                      {" "}
                      <b>1. Enhancing Teaching Skills and Performance:</b> We
                      provide practical strategies and techniques to help
                      teachers improve their instructional methods and classroom
                      management. Our goal is to ensure that teachers are
                      well-prepared to meet the diverse needs of their students
                      effectively.
                    </p>
                    <p>
                      <b>2. Awareness of New Trends in Education:</b> With the
                      introduction of the National Education Policy (NEP) 2020,
                      the educational landscape in India is undergoing
                      significant changes. Our programs are designed to help
                      teachers understand and implement the principles of NEP
                      2020, ensuring they are at the forefront of educational
                      advancements.
                    </p>
                    <p>
                      <b>3. Adopting Innovative Teaching Methods: </b>
                      We encourage teachers to explore and adopt innovative
                      teaching techniques that make learning more engaging and
                      effective. Our sessions include training on the use of
                      technology in the classroom, project-based learning, and
                      other modern pedagogical approaches.
                    </p>
                    <p>
                      <b>4. Making Learning a Joyful Experience: </b>
                      We believe that learning should be an enjoyable and
                      enriching experience for students. Our programs focus on
                      creating a positive and dynamic classroom environment
                      where students are motivated and excited to learn.
                    </p>
                    <p>
                      <b>5. Professional Enrichment through Collaboration: </b>
                      Our training programs provide a platform for teachers and
                      principals to learn from experts and collaborate with
                      peers. This collaborative approach fosters professional
                      growth and enriches the educational community.
                    </p>
                  </Col>
                  <Col lg="4" md="12" sm="12" className="order-lg-2 order-md-1">
                    <div className="image2">
                      <img alt="overview" src={Overview2} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="8" md="12" sm="12" className="order-1">
                    <div className="stats">
                      <h4>Year-wise Training Details</h4>
                      <div className="d-flex justify-content-center mt-1">
                        <p className="workshop">
                          Total Workshops = <b>434</b>
                        </p>
                        <p className="beneficiary">
                          Total Beneficiary Teachers = <b>21,893</b>
                        </p>
                      </div>
                      <div className="graph">
                        <img alt="graph" src={Graph} />
                      </div>
                      <p className="impNote">
                        <b>Note:</b> During COVID-19 period i.e. from 2020-2022,
                        webinars were conducted
                      </p>
                      <div className="note">
                        <p>
                          By participating in Navneet's Teacher’s Training
                          Program, educators gain valuable insights and tools
                          that enhance their teaching practice and contribute to
                          the overall improvement of the educational experience
                          for their students.
                          <br />
                          Join us in our mission to elevate the standards of
                          education and make learning a truly joyful and
                          impactful journey.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>

            <Footer />
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(TrainingSchedule);
