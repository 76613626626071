import React, { Component, Button as ButtonReact } from "react";
//import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
//import PasswordField from 'material-ui-password-field';
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { apiURL } from "../utils";
import axios from "axios";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./style.css";
import { Formik, Form } from "formik";
import * as yup from "yup";
//import { ETIME } from 'constants';
//import { Link, useHistory  } from 'react-router-dom';
import Alert from "@material-ui/lab/Alert";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import NumberVerified from "./NumberVerified";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { Helmet } from "react-helmet";

import ReactPixel from "react-facebook-pixel";
import { Spinner } from "react-bootstrap";

const FormControl = require("@material-ui/core/FormControl").default;
const FormHelperText = require("@material-ui/core/FormHelperText").default;
const InputLabel = require("@material-ui/core/InputLabel").default;

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
      width: "100%",
    },
  },
});

const signUpSchema = yup.object().shape({
  status: yup.string().required("Please select status."),
  // title: yup.string().required("Please select gender."),
  name: yup.string().required("This field is required."),
  //middle_name: yup.string().required("This field is required."),
  surname: yup.string().required("This field is required."),
  //number: yup.string().required("This field is required.").test('len', 'Must be exactly 10 characters', val => val.length === 10),
  number: yup.number().required("This field is required."),
  // email: yup.string().required("This field is required.").email(),
  // subject_taught: yup.string().required("This field is required."),
  // choose_school_collage: yup
  //   .string()
  //   .required("This field is required.")
  //   .nullable(),
  // school_collage: yup.string().required("This field is required.").nullable(),
  // state: yup.string().required("Please select state.").nullable(),
  city: yup.string().required("Please select city.").nullable(),
  // zila: yup.string().required("Please select zila.").nullable(),
  // boards: yup.string().required("This field is required."),
  // medium: yup.string().required("Please select medium."),
  // faculty: yup.string().required("Please select faculty."),
  // username: yup.string().required("This field is required."),
  password: yup.string().required("This field is required."),
  agree: yup
    .string()
    .required("Please Check, I agree to the Terms & Conditions"),
});

const disabledSignUpSchema = yup.object().shape({
  status: yup.string().required("Please select status."),
  // title: yup.string().required("Please select gender."),
  name: yup.string().required("This field is required."),
  surname: yup.string().required("This field is required."),
  number: yup.number().required("This field is required."),
  // email: yup.string().required("This field is required.").email(),
  // state: yup.string().required("Please select state.").nullable(),
  city: yup.string().required("Please select city.").nullable(),
  // zila: yup.string().required("Please select zila.").nullable(),
  // username: yup.string().required("This field is required."),
  password: yup.string().required("This field is required."),
  agree: yup
    .string()
    .required("Please Check, I agree to the Terms & Conditions"),
});

class Signup extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      state: "12",
      city: null,
      hasError: false,
      stateList: [],
      cityList: [],
      zilaList: [],
      password: "",
      showPassword: false,
      emailError: false,
      emailErrorMsg: "",
      numberError: false,
      numberErrorMsg: "",
      usernameError: false,
      usernameErrorMsg: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
      authMobile: "",
      otpBoxOpen: false,
      contactNumberVerified: false,
      sendOTPString: false,
      mobileNumber: "",
      isNumberVerified: false,
      isNumberVerifiedErrorMsg: "",
      isCorrectPin: false,
      pinError: "",
      isPinNumerical: false,
      incorrectPinError: "",
      get_school_collage: "",
      schooolCollageList: [],
      disabledField: "no",
      mobNumDisabled: false,
      isSubmitting: false,
      mobileVerifyError: false,
    };
    // this.get_state = this.get_state.bind(this);
    this.get_city = this.get_city.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    // this.check_email = this.check_email.bind(this);
    this.check_number = this.check_number.bind(this);
    this.sendOTP = this.sendOTP.bind(this);
    this.otpBoxClose = this.otpBoxClose.bind(this);
    this.onClickOTPClose = this.onClickOTPClose.bind(this);
    // this.check_username = this.check_username.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    // this.get_state();
    this.get_city();
    // if (this.props.getSocialResponse) {
    //   this.check_email(this.props.getSocialResponse.email);
    // }
  }

  handleChangeState(value) {
    this.setState({ state: value });
    // this.get_city(value);
    this.get_zila(value);
  }

  // choose_school_collage = (value) => {
  //   this.setState({ get_school_collage: value });
  //   // alert(value)
  // };

  // get_school_collage_key = (key) => {
  //   //alert(key)
  //   const headers = {
  //     "content-type": "application/json",
  //     Accept: "application/json",
  //   };
  //   const body = { key: key, ik: this.state.get_school_collage };
  //   axios
  //     .post(apiURL("school_collage/get_school_collage"), body, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       const resultData = response.data;
  //       console.log(resultData.result);
  //       if (resultData.status === true || resultData.status === 1) {
  //         this.setState({ schooolCollageList: resultData.result });
  //       }
  //       /* else{
  //             this.setState({ emailError: false, emailErrorMsg: ""});
  //           }*/
  //     });
  // };

  handleChangeCity(value) {
    this.setState({ city: value });
  }

  // handleChangeZila(value) {
  //   this.setState({ zila: value });
  // }

  // check_email = (email) => {
  //   if (email) {
  //     if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
  //       const headers = {
  //         "content-type": "application/json",
  //         Accept: "application/json",
  //       };
  //       const body = { email: email };
  //       axios
  //         .post(apiURL("register/check_email"), body, { headers: headers })
  //         .then((response) => {
  //           const resultData = response.data;
  //           if (resultData.status === true || resultData.status === 1) {
  //             this.setState({
  //               emailError: true,
  //               emailErrorMsg: resultData.message,
  //             });
  //           } else {
  //             this.setState({ emailError: false, emailErrorMsg: "" });
  //           }
  //         });
  //     } else {
  //       this.setState({
  //         emailError: true,
  //         emailErrorMsg: "Please enter valid email address",
  //       });
  //     }
  //   }
  // };

  check_number = (e) => {
    const re = /^[6-9]\d{9}$/;
    if (re.test(e.target.value)) {
      if (e.target.value) {
        const headers = {
          "content-type": "application/json",
          Accept: "application/json",
        };
        const body = { contact: e.target.value };
        axios
          .post(apiURL("register/check_number"), body, { headers: headers })
          .then((response) => {
            const resultData = response.data;
            if (resultData.status === true || resultData.status === 1) {
              this.setState({
                numberError: true,
                numberErrorMsg: resultData.message,
              });
            } else {
              this.setState({ numberError: false, numberErrorMsg: "" });
            }
          });
      }
    } else {
      this.setState({
        numberError: true,
        numberErrorMsg: "Please enter valid only numbers",
      });
    }
  };

  numberLengthValidate = (e) => {
    if (e.target.value.length !== 10) {
      this.setState({
        isNumberVerified: true,
        isNumberVerifiedErrorMsg: "Mobile number must be exactly 10 digits",
      });
    } else {
      this.setState({ isNumberVerified: false, isNumberVerifiedErrorMsg: "" });
    }
  };

  sendOTPString = (e) => {
    if (e.target.value.length === 10 && !this.state.numberError) {
      this.setState({
        numberError: false,
        numberErrorMsg: "",
        sendOTPString: true,
        mobileNumber: e.target.value,
        //isNumberVerified: false,
        //isNumberVerifiedErrorMsg: '',
      });
    } else {
      this.setState({
        numberError: false,
        numberErrorMsg: "",
        sendOTPString: false,
        mobileNumber: e.target.value,
        //isNumberVerified: true,
        //isNumberVerifiedErrorMsg: 'Please verify your mobule number!',
      });
    }
  };

  sendOTP = (e) => {
    //alert(e.target.value)
    //const number = document.getElementById('number').value;
    //alert(this.state.mobileNumber)
    if (
      !this.state.contactNumberVerified &&
      e.target.value.length === 10 &&
      !this.state.numberError &&
      this.state.authMobile.length === 0
    ) {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        number: e.target.value,
        //"number": this.state.mobileNumber,
      };
      axios
        .post(apiURL("register/send_otp"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              authMobile: atob(decodeURIComponent(resultData.result)),
              // otpBoxOpen: true,
              sendOTPString: false,
              mobileNumber: document.getElementById("number").value,
              mobNumDisabled: true,
            });
            localStorage.setItem("otp", resultData.result);
          }
        });
    } else if (this.state.authMobile.length > 0) {
      this.setState({
        otpBoxOpen: false,
        sendOTPString: false,
        mobileNumber: document.getElementById("number").value,
      });
    } else {
      this.setState({ sendOTPString: false });
    }
  };

  otpBoxClose = (event) => {
    if (event === true) {
      this.setState({
        authMobile: "",
        otpBoxOpen: false,
        contactNumberVerified: true,
        sendOTPString: false,
        isNumberVerified: false,
        isNumberVerifiedErrorMsg: "",
      });
    }
    /*else{
        this.setState({
          authMobile: '',
          otpBoxOpen: false,
          contactNumberVerified: false,
          sendOTPString: false
      })
      }*/
  };

  onClickOTPClose = (event) => {
    if (event === true) {
      this.setState({
        // authMobile: "",
        otpBoxOpen: false,
        contactNumberVerified: false,
        isNumberVerified: true,
        isNumberVerifiedErrorMsg: `Verify your mobile number by clicking Enter OTP`,
        //sendOTPString: false
      });
    }
  };

  changeNumber = (event) => {
    if (event === true) {
      this.setState({
        // authMobile: "",
        otpBoxOpen: false,
        contactNumberVerified: false,
        isNumberVerified: false,
        isNumberVerifiedErrorMsg: "",
        mobNumDisabled: false,
        //sendOTPString: false
      });
    }
  };

  // check_username = (e) => {
  //   if (e.target.value) {
  //     const headers = {
  //       "content-type": "application/json",
  //       Accept: "application/json",
  //     };
  //     const body = { username: e.target.value };
  //     axios
  //       .post(apiURL("register/check_username"), body, { headers: headers })
  //       .then((response) => {
  //         const resultData = response.data;
  //         if (resultData.status === true || resultData.status === 1) {
  //           this.setState({
  //             usernameError: true,
  //             usernameErrorMsg: resultData.message,
  //           });
  //         } else {
  //           this.setState({ usernameError: false, usernameErrorMsg: "" });
  //         }
  //       });
  //   }
  // };

  // get_state = () => {
  //   const headers = {
  //     "content-type": "application/json",
  //     Accept: "application/json",
  //   };
  //   axios
  //     .get(apiURL("common/get_state"), { headers: headers })
  //     .then((response) => {
  //       const resultData = response.data;
  //       if (resultData.status === true || resultData.status === 1) {
  //         this.setState({ stateList: resultData.result });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  get_city = (stateId) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(apiURL("common/get_city/" + 12), { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({ cityList: resultData.result });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get_zila = (stateId) => {
  //   const headers = {
  //     "content-type": "application/json",
  //     Accept: "application/json",
  //   };
  //   axios
  //     .get(apiURL("common/get_zila/" + stateId), { headers: headers })
  //     .then((response) => {
  //       const resultData = response.data;
  //       if (resultData.status === true || resultData.status === 1) {
  //         this.setState({ zilaList: resultData.result });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  check_pinInput = (e) => {
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value)) {
      this.setState({ isPinNumerical: false, incorrectPinError: "" });
    } else {
      this.setState({
        isPinNumerical: true,
        incorrectPinError: "Pin must be numerical only",
      });
    }
  };
  check_pinLength = (e) => {
    if (e.target.value.length !== 4) {
      this.setState({
        isCorrectPin: true,
        pinError: "Pin must be exactly 4 digits",
      });
    } else {
      this.setState({ isCorrectPin: false, pinError: "" });
    }
  };
  passwordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleClickShowPassword = (event) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCheckboxChange = (event) => {
    this.setState({ checked: event.target.checked });
    if (event.target.checked === false) {
      this.setState({
        checkBoxError: true,
        checkBoxErrorMsg: "Please Check, I agree to the Terms & Conditions",
      });
    } else {
      this.setState({
        checkBoxError: false,
        checkBoxErrorMsg: "",
      });
    }
  };
  handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };

  handleClick() {
    this.setState({ hasError: false });
    if (!this.state.selected) {
      this.setState({ hasError: true });
    }
  }

  onSubmit = (values) => {
    this.setState({ isSubmitting: true });
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      first_name: values.name,

      contact: values.number,

      city: values.city,

      login_pin: values.password,
      source: this.props.getSocialResponse.source
        ? this.props.getSocialResponse.source
        : "web",
      // origin: window.location.origin.toString(),
      status: values.status,

      last_name: values.surname,
    };

    axios
      .post(apiURL("register/regsiter_user"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        console.log(resultData);
        if (resultData.status === true || resultData.status === 1) {
          this.props.setState(0);
          // localStorage.setItem("login_status", true);
          localStorage.setItem(
            "user_detail",
            JSON.stringify(resultData.result)
          );
          this.setState({
            snackBarMessage: "Successfully registered",
            showSnackbar: true,
            severityColor: "success",
            isSubmitting: false,
          });

          this.props.propsData.history.push("/update-profile");

          // this.props.propsData.history.push(
          //   "/?utm_source=web&utm_medium=all-sources&utm_id=conversion"
          // );
          // const history = useHistory();
          // history.push(
          //   "/?utm_source=web&utm_medium=all-sources&utm_id=conversion"
          // );
          /*
              this.props.propsData.history.push('/sign');
              const history = useHistory();
              history.push("/sign");
              //alert("register succfully");*/

          // const advancedMatching = { em: values.email }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
          // const options = {
          //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          //   debug: false, // enable logs
          // };
          // ReactPixel.init("214864470093805", advancedMatching, options);

          // ReactPixel.pageView(); // For tracking page view
          // ReactPixel.track("track", "CompleteRegistration"); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
          //ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
          //ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
          //ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
        } else {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "error",
            isSubmitting: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.state({ isSubmitting: false });
      });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  handleChangeStatus = (value) => {
    if (value === "Others") {
      this.setState({ disabledField: "yes" });
    } else {
      this.setState({ disabledField: "no" });
    }
  };

  render() {
    const { classes } = this.props;

    //const { selected, hasError , selected1} = this.state;
    const filter = createFilterOptions();

    return (
      <React.Fragment>
        <Helmet>
          <title>SignUp - New Users</title>

          <meta charset="utf-8" />
          <link rel="icon" href="/navdisha-favicon.png" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="theme-color" content="#000000" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
          <meta name="robots" content="SignIn - Existing Users" />
          <link rel="canonical" href={window.location.href} />

          <meta name="description" content="SignIn - Existing Users" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Formik
          initialValues={{
            status: "",
            title: "",
            name: this.props.getSocialResponse
              ? this.props.getSocialResponse.name
              : "",
            middle_name: "",
            surname: "",
            number: "",
            email: this.props.getSocialResponse
              ? this.props.getSocialResponse.email
              : "",
            subject_taught: "",
            choose_school_collage: "",
            school_collage: "",
            state: "",
            city: "",
            zila: "",
            boards: "",
            medium: "",
            faculty: "",
            username: "",
            password: "",
            agree: "",
          }}
          validationSchema={
            this.state.disabledField === "yes"
              ? disabledSignUpSchema
              : signUpSchema
          }
          onSubmit={(values) => {
            //alert(JSON.stringify(values));
            /*if(!this.state.checkBoxError && !this.state.isNumberVerified && this.state.emailError)
                    {
                      this.onSubmit(values)
                      alert(JSON.stringify(values));
                      console.log(JSON.stringify(values)); 
                    } */
            !this.state.checkBoxError &&
            !this.state.isNumberVerified &&
            !this.state.numberError
              ? this.onSubmit(values)
              : console.log("error");
            //(!this.state.checkBoxError && !this.state.isNumberVerified && !this.state.numberError && !this.state.emailError)?alert(JSON.stringify(values)):alert("error");
          }}
        >
          {({ errors, handleChange, touched, setFieldValue }) => (
            <Form id="login" className={classes.root}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Grid container></Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    type="text"
                    fullWidth
                    variant="standard"
                    className="mt-2"
                    name="name"
                    label="First Name*"
                    value={
                      this.props.getSocialResponse
                        ? this.props.getSocialResponse.name
                        : this.state.name
                    }
                    onChange={handleChange}
                    error={errors.name && touched.name}
                    helperText={
                      errors.name && touched.name ? errors.name : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    type="text"
                    fullWidth
                    variant="standard"
                    className="mt-2"
                    name="surname"
                    label="Last Name*"
                    value={this.state.surname}
                    onChange={handleChange}
                    error={errors.surname && touched.surname}
                    helperText={
                      errors.surname && touched.surname ? errors.surname : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} xl={12} lg={12}>
                  <TextField
                    type="text"
                    fullWidth
                    variant="standard"
                    className="mt-2"
                    id="number"
                    name="number"
                    label="Mobile No.*"
                    value={this.state.number}
                    onChange={handleChange}
                    disabled={this.state.mobNumDisabled}
                    onBlur={(e) => {
                      this.check_number(e);
                      this.sendOTP(e);
                      this.numberLengthValidate(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button
                          disabled={
                            this.state.authMobile.length === 0 ||
                            this.state.numberError
                          }
                          style={{
                            justifyContent: "center",
                            borderWidth: 1,
                            fontSize: 8,
                          }}
                          onClick={() => {
                            this.setState({
                              otpBoxOpen: true,
                              sendOTPString: false,
                            });
                          }}
                        >
                          Enter OTP
                        </Button>
                      ),
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    // onFocus={e=>{
                    //   this.check_number(e);
                    //   this.sendOTP(e);
                    //   this.numberLengthValidate(e);
                    // }}
                    onKeyUp={(e) => this.check_number(e)}
                    error={
                      (errors.number && touched.number) ||
                      this.state.numberError ||
                      this.state.isNumberVerified ||
                      this.state.authMobile
                    }
                    helperText={
                      errors.number && touched.number
                        ? errors.number
                        : null ||
                          this.state.numberErrorMsg ||
                          this.state.isNumberVerifiedErrorMsg
                    }
                    onKeyPress={(e) => {
                      // Prevent any non-numeric input
                      if (!/^\d$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />

                  {this.state.sendOTPString &&
                  !this.state.contactNumberVerified &&
                  !this.state.numberError ? (
                    <div
                      className="w-100 small"
                      style={{ color: "red", fontWeight: "bold" }}
                    >
                      Please Verify your mobile number on click{" "}
                      <span
                        onClick={(e) => this.sendOTP(e)}
                        style={{ cursor: "pointer" }}
                      >
                        enter OTP
                      </span>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.contactNumberVerified ? (
                    <div
                      className="w-100 small"
                      style={{ color: "green", fontWeight: "bold" }}
                    >
                      Mobile number verified successfully
                    </div>
                  ) : (
                    ""
                  )}
                  {!this.state.numberError &&
                    !this.state.isNumberVerified &&
                    this.state.authMobile && (
                      <div className="w-100 small" style={{ color: "red" }}>
                        Now verify your mobile number by clicking "Enter OTP".
                      </div>
                    )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    onBlur={handleChange}
                    error={errors.status && touched.status}
                    className="mt-2"
                  >
                    <InputLabel htmlFor="status">Role*</InputLabel>
                    <Select
                      type="text"
                      name="status"
                      value={this.state.status}
                      onChange={(event) =>
                        this.handleChangeStatus(event.target.value)
                      }
                      input={<Input id="status" />}
                    >
                      <MenuItem value="Principal">Principal</MenuItem>
                      <MenuItem value="Teacher">Teacher</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors.status && touched.status ? errors.status : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    onBlur={handleChange}
                    error={errors.city && touched.city}
                    className="mt-2"
                  >
                    <InputLabel htmlFor="city">City*</InputLabel>
                    <Select
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={(event) =>
                        this.handleChangeCity(event.target.value)
                      }
                      input={<Input id="city" />}
                    >
                      <MenuItem value="">Select City</MenuItem>
                      {this.state.cityList.map((arrCity, index) => {
                        return (
                          <MenuItem key={arrCity.id} value={arrCity.city}>
                            {arrCity.city}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors.city && touched.city ? errors.city : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth className="mt-2">
                    <TextField
                      label="Set 4 Digit Pin*"
                      variant="standard"
                      type={this.state.showPassword ? "text" : "password"}
                      id="password"
                      inputProps={{ maxLength: 4, inputMode: "numeric" }}
                      onChange={(e) => this.passwordChange(e, "password")}
                      onKeyPress={this.handleKeyPress}
                      onBlur={(e) => {
                        this.check_pinLength(e);
                        this.check_pinInput(e);
                      }}
                      onKeyUp={handleChange}
                      onKeyDown={handleChange}
                      error={
                        (errors.password && touched.password) ||
                        this.state.isPinNumerical ||
                        this.state.isCorrectPin
                      }
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : null ||
                            this.state.incorrectPinError ||
                            this.state.pinError
                      }
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e) => this.handleClickShowPassword(e)}
                              onMouseDown={(e) =>
                                this.handleMouseDownPassword(e)
                              }
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl
                    required
                    error={
                      (errors.agree && touched.agree) ||
                      this.state.checkBoxError
                    }
                    component="fieldset"
                    className="mt-2"
                    size="small"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checked}
                            onClick={this.handleCheckboxChange}
                            onKeyUp={handleChange}
                            onKeyDown={handleChange}
                            onChange={handleChange}
                            name="agree"
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <a
                              href="https://navdisha.navneet.com/terms-and-conditions"
                              title="View Terms & Conditions"
                              style={{ textDecoration: "underline" }}
                            >
                              Terms & Conditions
                            </a>
                          </span>
                        }
                      />
                    </FormGroup>
                    <FormHelperText>
                      {" "}
                      {errors.agree && touched.agree
                        ? errors.agree
                        : null || this.state.checkBoxError
                        ? this.state.checkBoxErrorMsg
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="signUpBtn mt-4 text-capitalize"
                    disabled={this.state.isSubmitting || this.state.authMobile}
                  >
                    {this.state.isSubmitting ? (
                      <Spinner variant="light" />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
          key={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>

        <NumberVerified
          otpBoxOpen={this.state.otpBoxOpen}
          otpValue={this.state.authMobile}
          onClickOTPClose={(e) => this.onClickOTPClose(e)}
          otpBoxClose={(e) => this.otpBoxClose(e)}
          mobileNumber={this.state.mobileNumber}
          changeNumber={(e) => this.changeNumber(e)}
        />
      </React.Fragment>
    );
  }
}

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Signup);
