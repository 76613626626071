import React, { useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import certificate from "../../images/Navdisha-Certificate.jpeg";
import "../../style/Achievements.scss";
import CheckIcon from "@material-ui/icons/Check";
import Moment from "moment";
const Certificate = ({
  firstname,
  lastname,
  schoolName,
  trainingTitle,
  date,
  time,
  duration,
  location,
  eventType,
  onDownloaded,
}) => {
  const certificateRef = useRef();

  useEffect(() => {
    const downloadCertificate = () => {
      const input = certificateRef.current;
      html2canvas(input, {
        scale: 1,
        useCORS: true,
        imageTimeout: 0,
        logging: false,
        backgroundColor: null,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.85);
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
        pdf.save("certificate.pdf");
      });
    };

    downloadCertificate();
  }, []);
  const startTime = Moment(time, ["hh:mm A", "HH:mm"]).format("hh:mm A");
  const endTime = Moment(time, ["hh:mm A", "HH:mm"])
    .add(duration, "hours")
    .format("hh:mm A");
  return (
    <div
      ref={certificateRef}
      className="certificate"
      style={{
        position: "absolute",

        left: "-9999px", // Move it off-screen
      }}
    >
      <img src={certificate} alt="Certificate" style={{ width: "100%" }} />
      <div className="username">
        <p>
          {firstname} {lastname}
        </p>
      </div>
      <div className="schoolName">
        <p>{schoolName}</p>
      </div>
      <div className="eventTitle">
        <p>{trainingTitle}</p>
      </div>
      <div
        className={eventType === "Training" ? "trainingTick" : "webinarTick"}
      >
        <CheckIcon />
      </div>
      <div className="date">
        <p>{Moment(date).format("DD/MM/YYYY")}</p>
      </div>
      <div className="time">
        <p>
          {" "}
          {startTime}-{endTime} {"(" + duration + "hrs)"}
        </p>
      </div>
      <div className="location">
        <p>{location}</p>
      </div>
    </div>
  );
};

export default Certificate;
