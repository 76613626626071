import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import Button from "@material-ui/core/Button";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import VideocamIcon from "@material-ui/icons/Videocam";
import Appbar from "./Appbar";
import "../style/BookMark.scss";
import axios from "axios";
import { apiURL, getID } from "../utils";
import Moment from "moment";
import { Media, BImg } from "bootstrap-4-react";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Video_dialog from "../Video_dialog";
import Footer from "../components/Footer";
import RatingDialog from "./Rating";
import { Link } from "react-router-dom";
import UpcomingEveBookmark from "./Bookmark/UpcomingEveBookmark";
import PastWebTrain from "./Bookmark/PastWebTrain";
import VideoBookmark from "./Bookmark/VideoBookmark";
import GovtCircularBookmark from "./Bookmark/GovtCircularBookmark";
import ArticleBookmark from "./Bookmark/ArticleBookmark";
const BookMark = () => {
  const [pastTraining, setPastTraining] = useState([]);
  const [pastWebinar, setPastWebinar] = useState([]);
  const [videos, setVideos] = useState([]);
  const [news, setNews] = useState([]);
  const [article, setArticle] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getAllData();
  }, []);
  const getAllData = () => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/getBookmark"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.success === true) {
          setPastTraining(resultData.result.training);
          setPastWebinar(resultData.result.webinar);
          setVideos(resultData.result.video);
          setNews(resultData.result.news);
          setArticle(resultData.result.article_blog);
          setBlogs(resultData.result.blogs);
          setUpcomingEvents(resultData.result.upcoming_events);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isDataEmpty =
    pastTraining.length === 0 &&
    pastWebinar.length === 0 &&
    videos.length === 0 &&
    news.length === 0 &&
    article.length === 0 &&
    upcomingEvents.length === 0;

  const handleReadMore = (e, id, title) => {
    e.preventDefault();
    history.push("/training/" + id + "/" + title);
  };
  const handleReadMoreNews = (e, id, title) => {
    e.preventDefault();
    history.push("/news/" + id + "/" + title);
  };
  const handleReadMoreWeb = (e, id, title) => {
    e.preventDefault();
    history.push("/past-webinar-schedule/" + id + "/" + title);
  };
  const videoDialog = (e, videoUrl) => {
    setDialogOpen(true);
    setVideoUrl(videoUrl);
  };
  const videoDialogClose = () => {
    setDialogOpen(false);
    setVideoUrl("");
  };
  const redirectArticle = (e, id, title, category, category_id) => {
    if (category_id === "6") {
      history.push(
        "/blog/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    } else {
      history.push(
        "/article/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
    addView(id);
  };

  const addView = (id) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      article_id: id,
      blog_id: "",
      user_id: getID(),
    };
    axios
      .post(apiURL("views/add_views"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveUpWebinarBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedUpWebinar = upcomingEvents.filter(
            (item) => item.fldi_id !== id
          );
          setUpcomingEvents(updatedUpWebinar);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveTrainingBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_training",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true) {
          const updatedPastTraining = pastTraining.filter(
            (item) => item.training_id !== id
          );
          setPastTraining(updatedPastTraining);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveWebinarBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedPastWebinar = pastWebinar.filter(
            (item) => item.fldi_id !== id
          );
          setPastWebinar(updatedPastWebinar);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveVideoBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "video",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedVideo = videos.filter((item) => item.video_id !== id);
          setVideos(updatedVideo);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveGRBookMark = (id) => {
    console.log("id", id);
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "news",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedGR = news.filter((item) => item.news_id !== id);
          setNews(updatedGR);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveArticleBookMark = (e, id, category_id) => {
    // e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: category_id === "6" ? "blogs" : "articles",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          if (category_id === "6") {
            const updatedBlog = blogs.filter((item) => item.blog_id !== id);
            setBlogs(updatedBlog);
          } else {
            const updatedArticle = article.filter(
              (item) => item.article_id !== id
            );
            setArticle(updatedArticle);
          }
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Fragment>
      <Helmet>
        <title>BookMark</title>

        <meta charset="utf-8" />
        <link rel="icon" href="/navdisha-favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="Bookmark" />
        <link rel="canonical" href={window.location.href} />

        <meta name="description" content="Bookmark" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Appbar />
      <h2 className="text-center d-block w-100 heading">My Bookmarks</h2>
      {!isDataEmpty ? (
        <>
          {upcomingEvents.length !== 0 && (
            <UpcomingEveBookmark
              upcomingEvents={upcomingEvents}
              handleRemoveUpWebinarBookmark={handleRemoveUpWebinarBookMark}
              history={history}
            />
          )}
          {(pastTraining.length !== 0 || pastWebinar.length !== 0) && (
            <PastWebTrain
              pastWeb={pastWebinar}
              pastTrain={pastTraining}
              history={history}
              handleRemovePastWeb={handleRemoveWebinarBookMark}
              handleRemovePastTrain={handleRemoveTrainingBookMark}
            />
          )}
          {videos.length !== 0 && (
            <VideoBookmark
              videos={videos}
              history={history}
              handleRemoveVideoBookmark={handleRemoveVideoBookMark}
            />
          )}
          {news.length !== 0 && (
            <GovtCircularBookmark
              circularData={news}
              history={history}
              handleRemoveGRBookMark={handleRemoveGRBookMark}
            />
          )}
          {article.length !== 0 && (
            <ArticleBookmark
              articleData={article}
              history={history}
              handleRemoveArticleBookMark={handleRemoveArticleBookMark}
            />
          )}
        </>
      ) : (
        <div style={{ minHeight: "100px" }}></div>
      )}
      <div className="customFooter mt-0">
        <Footer />
      </div>
    </Fragment>
  );
};

export default BookMark;
