import React from 'react';
import { Row, Col } from 'bootstrap-4-react';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import { isLogin } from '../utils';

const Welcome = (props) => {
  const history = useHistory();

  const redirectSignUp = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/sign',
      state: {
        value: 1
      }
    });
  };

  return (
    <React.Fragment>
      <Row className="mx-0">
        <Col col="sm-6"></Col>
        <Col col="sm-6" className="TeacherBox">
          {/* <h2> Welcome to   </h2> */}
          {/* <h3>NavDisha Enriching Teacher's </h3> */}
          <h3>Welcome to NAVदिशा</h3>
          <h4>A CSR Initiative by Navneet Foundation for encouraging and enriching teachers.<br /> {!isLogin() ? "Register To Get Started" : ""}</h4>
          {!isLogin() ? <Link to={{ pathname: '/sign', state: { value: 1 } }}>
            <Button variant="contained" className="SignupBox">SIGN UP</Button>
          </Link> : ""}
          {/*<Button onClick={(e) => redirectSignUp(e)} variant="contained" className="SignupBox">SIGN UP</Button>*/}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Welcome;