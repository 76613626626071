import React, { Component, Fragment } from "react";
import { Row, Col, Container, Card } from "bootstrap-4-react";
import "../../style/ArticleMain.scss";
import { Button, TextField } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import axios from "axios";
import { apiURL, getEmail, getName } from "../../utils";
import Moment from "moment";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      comments: [],
      per: 2,
      page: 1,
      total: 0,
      showAll: false,
    };
  }

  componentDidMount() {
    this.getAllComments();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.category_id !== this.props.category_id ||
      prevProps.blogId !== this.props.blogId ||
      prevProps.articleId !== this.props.articleId
    ) {
      this.setState({ comments: [], page: 1 }, this.getAllComments);
    }
  }

  addComment = async (event) => {
    event.preventDefault();

    const { articleId } = this.props;
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      name: getName(),
      email: getEmail(),
      message: this.state.message,
      ...(this.props.category_id === "6"
        ? { blog_id: this.props.blogId }
        : { article_id: articleId }),
    };
    const url =
      this.props.category_id === "6"
        ? apiURL("blog_comment/add")
        : apiURL("Article_comment/add");

    try {
      const response = await axios.post(url, body, { headers });
      const responseData = response.data;

      if (responseData.status === true || responseData.status === 1) {
        // Create new comment with the current date
        const newComment = {
          fldv_name: getName(),
          fldv_created_date: new Date(), 
          fldt_message: this.state.message,
        };
        // Update state without triggering re-render of full page
        this.setState((prevState) => ({
          message: "",
          comments: [newComment, ...prevState.comments], // Add new comment at the top of the list
          total: prevState.total + 1, // Increment total count
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  getAllComments = async () => {
    const { articleId } = this.props;
    const headers = { "Content-type": "application/json" };
    const body = {
      ...(this.props.category_id === "6"
        ? { blog_id: this.props.blogId }
        : { article_id: articleId }),
    };
    const url =
      this.props.category_id === "6"
        ? apiURL(
            `blog_comment?per_page=${this.state.per}&page=${this.state.page}`
          )
        : apiURL(
            `article_comment?per_page=${this.state.per}&page=${this.state.page}`
          );

    try {
      const response = await axios.post(url, body, { headers });
      const responseData = response.data;
      if (responseData.status === true || responseData.status === 1) {
        const updatedData = [...this.state.comments, ...responseData.result];
        this.setState({ comments: updatedData, total: responseData.total });
      } else {
        this.setState({ comments: [] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (event) => {
    this.setState({ message: event.target.value });
  };

  viewMore = () => {
    if (this.state.comments.length === this.state.total) {
      this.setState(
        { showAll: false, page: 1, comments: this.state.comments.slice(0, 2) },
        () => {
          document.getElementById("comments-container").scrollIntoView();
        }
      );
    } else {
      // Load more comments
      this.setState(
        (prevState) => ({ page: prevState.page + 1, showAll: true }),
        this.getAllComments
      );
    }
  };

  render() {
    const { comments, total, showAll } = this.state;

    const isLastPage = comments.length === total;

    const commentsToDisplay = showAll ? comments : comments.slice(0, 2);

    return (
      <Fragment>
        <section className="comments">
          <Container id="comments-container">
            <h2>Comments</h2>
            <Row>
              {commentsToDisplay.map((comment) => (
                <Col sm="12" key={comment.fldv_created_date} className="mb-1">
                  <Card>
                    <div className="information">
                      <h5>{comment.fldv_name}</h5>
                      <p>
                        {Moment(comment.fldv_created_date).format(
                          "D MMMM, YYYY"
                        )}
                      </p>
                    </div>
                    <div className="comment">
                      <p>{comment.fldt_message}</p>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            {total > 2 && (
              <div className="view-more-container">
                <span style={{ cursor: "pointer" }} onClick={this.viewMore}>
                  {isLastPage && showAll ? (
                    <>
                      Show less comments <ArrowUpward />
                    </>
                  ) : (
                    <>
                      View more comments <ArrowDownward />
                    </>
                  )}
                </span>
              </div>
            )}
          </Container>
        </section>
        <section className="addComment">
          <Container>
            <Card>
              <div className="form">
                <TextField
                  label="Add a comment..."
                  variant="outlined"
                  value={this.state.message}
                  onChange={this.handleChange}
                />
                <Button onClick={this.addComment} disabled={this.state.message.length===0}>POST</Button>
              </div>
            </Card>
          </Container>
        </section>
      </Fragment>
    );
  }
}

export default Comments;
