import React, { Fragment, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";
import { Row, Col } from "bootstrap-4-react";
import ResetPin from "../../home/ResetPin";
import { getID, apiURL } from "../../utils";
import { useHistory } from "react-router-dom";
import { BiLogOut } from "@react-icons/all-files/bi/BiLogOut";
const Security = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPinChangeDialog, setPinChangeDialog] = useState(false);

  const handlePinDialogOpen = () => {
    setPinChangeDialog(true);
  };
  const handlePinDialogClose = () => {
    setPinChangeDialog(false);
  };
  const handleDeleteClick = () => {
    setOpenDialog(true);
  };
  const handleConfirmDelete = () => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      fldi_id: getID(),
    };
    axios
      .post(apiURL("teacher/delete_profile"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;

        if (resultData.status === true || resultData.status === 1) {
          localStorage.clear();

          history.replace("/sign");
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleLogOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.localStorage.clear();

    history.push("/");
  };
  return (
    <Fragment>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete your account?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} color="primary">
            Yes
          </Button>
          <Button onClick={handleCloseDialog} color="secondary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Row className="securityHeader">
        <div className="heading">
          <h5>Security Pin</h5>
        </div>
      </Row>
      <Row>
        <div className="securityContainer">
          <Typography className="securityLabel">Security Pin</Typography>
          <div className="securityPin">
            <div className="pinValue">
              <Typography>****</Typography>
            </div>
            <Button variant="contained" onClick={handlePinDialogOpen}>
              Change Pin
            </Button>
            <ResetPin
              openDialog={openPinChangeDialog}
              onCloseDialog={handlePinDialogClose}
            />
          </div>
        </div>
        <div className="action">
          <div className="delButton">
            <Button onClick={handleDeleteClick}>Delete Account</Button>
          </div>
          <div className="logoutButton" onClick={handleLogOut}>
            <Button>
              <BiLogOut />
              Logout
            </Button>
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

export default Security;
