import React from "react";
import { Row, Col } from "bootstrap-4-react";
import aboutTeacher from "../images/about.jpg";

import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Container } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 0),
    fontSize: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
}));

const AboutTeacher = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <section className="AboutBox">
        <Container>
          <Row>
            <Col col="lg-8 md-12 sm-12" className="order-1">
              <p>
                Navneet’s ‘NavDisha’ portal represents yet another illustrious
                initiative by Navneet aimed at the enrichment of teachers. This
                visionary platform was conceptualized and introduced by Mr. Anil
                Gala during the pandemic, a period when traditional, in-person
                training sessions had come to a halt.
              </p>
              <p>
                In 2016, Navneet foundation launched a programme for capacity
                building of educators. The programme is aimed to provide
                opportunity to the teachers and the Principals to learn from
                experts while collaborating with the peers for professional
                enrichment
              </p>
              <p>
                The launch of the NavDisha portal was fittingly celebrated on
                the 5th of September 2020, coinciding with the esteemed occasion
                of Teachers' Day.
                <br />
                The portal began by updating teachers on significant
                developments and recent changes in the education system, while
                also providing a platform for interaction to aid their
                professional growth. Entirely dedicated to educators, Navneet
                believes that devoted teachers have the power to transform
                students by imparting knowledge, skills, values, and attitudes.
                The NavDisha portal aspires to enrich and empower these
                dedicated teachers.
              </p>

              <p>
                NavDisha offers a diverse range of educational content to its
                users.
              </p>
            </Col>
            <Col col="lg-4 md-12 sm-12" className="order-lg-2 order-2">
              <img src={aboutTeacher} className="img-fluid w-100 rounded" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="aboutInfo">
        <Container maxWidth="lg" className="container">
          <Row>
            <div className="rightContainer">
              <Card className="rightCard">
                <div className="rightCircle">Physical Training</div>
                <div className="rightInfo">
                  <p>
                    Our comprehensive physical training sessions are designed to
                    equip educators with the practical skills and hands-on
                    experience needed to excel in the classroom. These
                    interactive workshops focus on various teaching
                    methodologies, classroom management strategies, and
                    subject-specific techniques, ensuring teachers are
                    well-prepared to foster a dynamic and engaging learning
                    environment.
                  </p>
                </div>
              </Card>
            </div>
          </Row>
          <Row>
            <div className="leftContainer">
              <Card className="leftCard">
                <div className="leftInfo">
                  <p>
                    Stay updated with the latest trends and advancements in
                    education through our engaging webinars. Led by industry
                    experts, these online sessions cover a wide range of topics,
                    including innovative teaching practices, educational
                    technology, and professional development. Our webinars offer
                    flexible learning opportunities that can be accessed from
                    anywhere, making it easy for educators to stay informed and
                    inspired.
                  </p>
                </div>
                <div className="leftCircle">Webinars</div>
              </Card>
            </div>
            <div className="rightContainer">
              <Card className="rightCard">
                <div className="rightCircle">Govt. Circulars/GR</div>
                <div className="rightInfo">
                  <p>
                    We provide the latest government circulars related to
                    education, ensuring that teachers and educational
                    institutions stay compliant with current regulations and
                    policies. Our platform offers easy access to official
                    documents, guidelines, and updates from education
                    authorities, helping educators stay informed about important
                    changes and requirements in the education sector.
                  </p>
                </div>
              </Card>
            </div>
            <div className="leftContainer">
              <Card className="leftCard">
                <div className="leftInfo">
                  <p>
                    Our articles section features insightful and informative
                    pieces written by experienced educators, researchers, and
                    thought leaders in the field of education. Covering a wide
                    array of topics, from innovative teaching strategies to
                    educational psychology, these articles provide valuable
                    knowledge and practical tips to enhance teaching
                    effectiveness and support professional growth.
                  </p>
                </div>
                <div className="leftCircle">Articles</div>
              </Card>
            </div>
            <div className="rightContainer">
              <Card className="rightCard">
                <div className="rightCircle">Blogs</div>
                <div className="rightInfo">
                  <p>
                    Our blog is a space for educators to share their
                    experiences, insights, and reflections on various aspects of
                    teaching and learning. From personal anecdotes to expert
                    advice, our blog posts offer a diverse range of perspectives
                    and ideas that inspire and empower teachers. Join our
                    community of educators and contribute to the ongoing
                    conversation about improving education for all.
                  </p>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </section>
      <section className="addtionalInfo">
        <Container maxWidth="md">
          <div className="additionalData">
            <p>
              {" "}
              Additionally, a series of videos on the ‘Methods of Teaching’ has
              been developed by us and are made available on NavDisha as well as
              Navneet YouTube channel which will also benefit teachers.
            </p>
            <p>
              We look forward to many more teachers benefitting themselves
              through these initiatives and many more to come.
            </p>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutTeacher;
