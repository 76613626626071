import React from "react";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import { Link } from "react-router-dom";
import futureStudents from "../images/future-students.png";
import ourCommunity from "../images/TrTeacherHome.jpg";
import govtcirculars from "../images/govt-circulars.png";
import articles from "../images/articlesi.png";
import { Grid } from "@material-ui/core";

const Student = () => {
  return (
    <React.Fragment>
      <section className="StudentBox">
        <Container>
          <Grid container spacing={3} className="mainGrid">
            <Grid item xs={6} sm={6} lg={6} className="text-center">
              <Card className="webinar-card">
                <Link to="/overview">
                  <Card.Body>
                    <img
                      src={ourCommunity}
                      className="img-fluid"
                      alt="true"
                    />
                    <Card.Title>Trainings & Webinars</Card.Title>
                    <Card.Text>
                      Join our expert-led webinars and engage in hands-on,
                      interactive physical trainings designed to enhance your
                      teaching skills and stay updated on the latest
                      educational trends and methodologies. Participate in
                      live discussions, Q&A sessions, and gain valuable
                      insights from industry leaders.
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6} lg={6} className="text-center">
              <Card className="govt-card">
                <Link to="/maharashtra-state-board">
                  <Card.Body>
                    <img
                      src={govtcirculars}
                      className="img-fluid"
                      alt="true"
                    />
                    <Card.Title>Govt Circulars/GR</Card.Title>
                    <Card.Text>
                      Stay informed with the latest government circulars
                      relevant to education. Access official updates,
                      guidelines, and policies to ensure compliance and stay
                      ahead in the education sector.
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="mainGrid">
            <Grid item xs={6} sm={6} lg={6} className="text-center">
              <Card className="articles-card">
                <Link to="/academic-essays">
                  <Card.Body>
                    <img src={articles} className="img-fluid" alt="true" />
                    <Card.Title>Articles</Card.Title>
                    <Card.Text>
                      Explore a rich collection of articles and blogs covering
                      a wide range of educational topics. Our expert authors
                      provide in-depth analysis, tips, and best practices to
                      support your professional growth. Connect with the
                      community and find inspiration for your teaching
                      journey.
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6} lg={6} className="text-center">
              <Card className="teacher-training-card">
                <Link to="/tr-for-teacher" className="link">
                  <Card.Body>
                    <img
                      src={futureStudents}
                      className="img-fluid"
                      alt="true"
                    />
                    <Card.Title>Tr for Teacher</Card.Title>
                    <Card.Text>
                      Navneet launched the 'Tr. For Teacher' initiative to
                      shine a spotlight on the tireless efforts of educators
                      and to advocate for their rightful recognition. You can
                      now download your personalized visiting card with the
                      prefix Tr.
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Student;
