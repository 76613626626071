import React, { Component } from 'react';  
//import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
//import PasswordField from 'material-ui-password-field';
//import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
//import Input from "@material-ui/core/Input";  
//import MenuItem from "@material-ui/core/MenuItem";
//import Select from "@material-ui/core/Select";
import { apiURL, isLogin, getName, getEmail } from '../utils'; 
import axios from 'axios';   
import { Grid, Snackbar } from '@material-ui/core';   
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Formik, Form } from 'formik';
import * as yup from 'yup'; 
import Alert from '@material-ui/lab/Alert'; 

// const FormControl = require('@material-ui/core/FormControl').default;
// const FormHelperText = require('@material-ui/core/FormHelperText').default;
// const InputLabel = require('@material-ui/core/InputLabel').default;

const styles = theme => ({
   root: {
      '& > *': {
         margin: theme.spacing(0),
         width: '100%',
      },
   },
});

const CommentFormSchema = yup.object().shape({
   name: yup.string().required("This field is required."), 
   email: yup
     .string()
     .email()
     .required("This field is required."),
   message: yup.string().required("This field is required."),
 }); 

class CommentForm extends Component { 
   _isMounted = false; 
   constructor(props){
      super(props);
      this.state = { 
         showSnackbar: false,
         snackBarMessage: '',
         severityColor:'',
         // name: null,
         // email: null,
         // message: null,
      } 
      this.submitBlogComment = this.submitBlogComment.bind(this);
   }

   componentDidMount()
   {
      this._isMounted = true; 
   }
 
   componentWillUnmount() {
      this._isMounted = false; 
   }

   submitBlogComment = (values) => { 
      const headers = {
          'content-type': 'application/json',
          'Accept': 'application/json'
      }
      const body = {
         "name": values.name,
         "email": values.email,
         "message": values.message,
         "blog_id": this.props.BlogId,
      }
      axios.post(apiURL('blog_comment/add'),body, {headers: headers})
      .then(response => {
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          {
              this.setState({ snackBarMessage: resultData.message, showSnackbar: true, severityColor:'success' });
              
          }
          else{
            this.setState({ snackBarMessage: "Server error! Please Try after some time",showSnackbar: true , severityColor:'error'});
          } 
          this.setState({ name: " ", email: " ", message: " "});  
          this.props.ReturnComments(true);
          this.componentDidMount();
      })
      .catch(error => {
          console.log(error)
      })  
  }

  snackBarClose = (event, reason) => {
   if (reason === 'clickaway') {
       return;
     }
     this.setState({ showSnackbar: false }); 
}

  
   render() {
      const { classes } = this.props; 
      return (
         <React.Fragment>
            {/* <form className="careerForm feedBackForm commentForm" noValidate autoComplete="off" > */}
            <Formik
               initialValues={{
                  name:  isLogin()?getName():"", 
                  email: isLogin()?getEmail():"",
                  message: "",
               }}
               validationSchema={CommentFormSchema}
               onSubmit={(values, { resetForm }) => { 
                  //alert(JSON.stringify(values));
                  this.submitBlogComment(values);
                  resetForm();
               }}
            >
            {({ errors, handleChange, touched }) => (
            <Form id="blog-comment" className="careerForm feedBackForm commentForm">
               <Grid container spacing={1}> 

                  {!isLogin()?<Grid item xs={12} sm={12} md={6}>
                     <TextField id="filled-basic"  value={this.state.name}  autocomplete="off"  variant="filled" type="text" className="mt-4" name="name" id="name"  label="Name" onChange={handleChange} error={errors.name && touched.name} />
                     <div className="w-100 small" style={{color:"red"}}> {errors.name && touched.name? errors.name: null} </div>
                  </Grid>:""}

                  {!isLogin()?<Grid item xs={12} sm={12} md={6}>
                     <TextField id="filled-basic" value={this.state.email} autocomplete="off"  variant="filled" type="text" className="mt-4" name="email" id="email" label="Email" onChange={handleChange} error={errors.email && touched.email} />
                     <div className="w-100 small" style={{color:"red"}}>{errors.email && touched.email ? errors.email : null}  </div>
                  </Grid>:""}

                  <Grid item xs={12} sm={12}>
                     <TextareaAutosize rowsMax={4} value={this.state.message} className="w-100 mt-4 textAreaBox" aria-label="maximum height" placeholder="Message" name="message" id="message" onChange={handleChange} />
                     <div className="w-100 small" style={{color:"red"}}>  {errors.message && touched.message ? errors.message: null} </div>
                  </Grid> 

                  <Grid item xs={12} sm={12} > 
                     <Button type="submit" className="CheckButton text-center mt-3"> SEND MESSAGE </Button>
                  </Grid>

               </Grid>
               </Form>
               )}
            </Formik>
            {/* </form> */}
            <Snackbar anchorOrigin={{  vertical: "top", horizontal: "center" }} open={this.state.showSnackbar === true} autoHideDuration={6000} onClose={this.snackBarClose}>
               <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
               { this.state.snackBarMessage }
               </Alert>
            </Snackbar>
         </React.Fragment>
      );
   }
}

CommentForm.propTypes = {
   classes: PropTypes.object.isRequired
};
export default withStyles(styles)(CommentForm);   