export  const setMenus=[
        {
            "fldi_id": "1",
            "fldv_name": "Home",
            "fldv_sub_title": "NAVदिशा",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "/",
            "fldv_tool_tip_title": "होम",
            "arrChilds": []
        },
        {
            "fldi_id": "2",
            "fldv_name": "About Us",
            "fldv_sub_title": "Our Story",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "/about-us",
            "fldv_tool_tip_title": "नवदिशा विषयी - नवनीत फाउंडेशनने खास शिक्षकांसाठी केलेल्या पोर्टलविषयी.",
            "arrChilds": []
        },
        {
            "fldi_id": "6",
            "fldv_name": "Trainings",
            "fldv_sub_title": "Online Trainings",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "",
            "fldv_tool_tip_title": "शिक्षक प्रशिक्षण -  शिक्षक समृद्धीकरणासाठी आयोजित शिक्षक प्रशिक्षण कार्यशाळा.",
            "arrChilds": [
                {
                    "fldi_id": "7",
                    "fldv_name": "Overview",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "6",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/overview",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "10",
                    "fldv_name": "Upcoming Events",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "6",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/upcoming-webinars",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "8",
                    "fldv_name": "Past Trainings",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "6",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/past-trainings",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "11",
                    "fldv_name": "Past Webinars",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "6",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/past-webinars",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "4",
                    "fldv_name": "Videos",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "6",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/video",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                }
            ]
        },
        {
            "fldi_id": "5",
            "fldv_name": "Articles",
            "fldv_sub_title": "Library",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "/articles",
            "fldv_tool_tip_title": " शिक्षणविषयक लेख - विषयनिहाय लेख, मुलाखती, यशोगाथा, पुस्तक परिचय, प्रेरणादायी विचार इत्यादी.",
            "arrChilds": [
                {
                    "fldi_id": "16",
                    "fldv_name": "Blogs",
                    "fldv_sub_title": "Thoughts",
                    "fldi_parent_id": "5",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/blogs",
                    "fldv_tool_tip_title": "ब्लॉग्ज् - शिक्षकांना उपयुक्त मार्गदर्शनपर ब्लॉग्ज्.",
                    "arrChilds": []
                },
                {
                    "fldi_id": "24",
                    "fldv_name": "Academic Essays",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "5",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/academic-essays",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "25",
                    "fldv_name": "Success Stories",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "5",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/success-stories",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "26",
                    "fldv_name": "Interviews",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "5",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/interviews",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "27",
                    "fldv_name": "Book Recommendations",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "5",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/book-recommendations",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },
                {
                    "fldi_id": "28",
                    "fldv_name": "Self Development",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "5",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/self-development",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                }
            ]
        },
        {
            "fldi_id": "15",
            "fldv_name": "Govt. Circulars/GR",
            "fldv_sub_title": "Updates",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "",
            "fldv_tool_tip_title": " शासन निर्णय/ परिपत्रके - शासनाच्या शालेय शिक्षण विभागाकडून वेळोवेळी प्रसिद्ध होणारे शासन निर्णय/शासन परिपत्रके.",
            "arrChilds": [
                {
                    "fldi_id": "31",
                    "fldv_name": "Central Board",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "15",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/central-board",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
                },{
                    "fldi_id": "19",
                    "fldv_name": "MH State Board",
                    "fldv_sub_title": "",
                    "fldi_parent_id": "15",
                    "flg_status": "1",
                    "flg_is_deleted": "1",
                    "fldv_to_path": "/maharashtra-state-board",
                    "fldv_tool_tip_title": "",
                    "arrChilds": []
               }
            ]
        },
        {
            "fldi_id": "17",
            "fldv_name": "FAQ",
            "fldv_sub_title": "Common questions",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "/faq",
            "fldv_tool_tip_title": "शंका-समाधान शंका-समाधान आणि अभिप्राय (मराठी-कुमारभारती) शंका-समाधान ",
            "arrChilds": []
        },
        {
            "fldi_id": "23",
            "fldv_name": "Tr. for Teacher",
            "fldv_sub_title": "",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "/tr-for-teacher",
            "fldv_tool_tip_title": "",
            "arrChilds": []
        },
        {
            "fldi_id": "18",
            "fldv_name": "Contact Us",
            "fldv_sub_title": "",
            "fldi_parent_id": "0",
            "flg_status": "1",
            "flg_is_deleted": "1",
            "fldv_to_path": "/contact-us",
            "fldv_tool_tip_title": "",
            "arrChilds": []
        }
    ]
