import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import {
  apiURL,
  getContact,
  getEmail,
  getLastName,
  getName,
  isLogin,
} from "../utils";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const formSchema = yup.object().shape({
  name: yup.string().required("This field is required."),
  contact: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  topic: yup.string().required("This field is required."),
  message: yup.string().required("This field is required."),
});

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  asterisk: {
    color: "red",
  },
});

const ContactForm = (props) => {
  const { classes, topic, userData, history } = props;

  const [state, setState] = useState({
    returnMessage: "",
    showSnackbar: false,
    snackBarMessage: "",
    severityColor: "",
    reload: false,
    contact_contactError: false,
    contact_contactErrorMsg: "",
    isSubmitting: false,
  });

  const onSubmitContactUsForm = (values, { resetForm }) => {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(apiURL("contact_us_enquiry/add"), values, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setState((prevState) => ({
            ...prevState,
            snackBarMessage:
              "Thank you for your message! We will contact you soon",
            showSnackbar: true,
            severityColor: "success",
            isSubmitting: false,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            snackBarMessage: "Server error! Please try after some time",
            showSnackbar: true,
            severityColor: "error",
            isSubmitting: false,
          }));
        }
        resetForm();
      })
      .catch((error) => {
        console.log(error);
        setState((prevState) => ({ ...prevState, isSubmitting: false }));
      });
  };

  const snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((prevState) => ({ ...prevState, showSnackbar: false }));
  };

  const contactNumberValidate = (e) => {
    const re = /^[0-9\b]+$/;
    if (!re.test(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        contact_contactError: true,
        contact_contactErrorMsg: "This field accepts only digits.",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        contact_contactError: false,
        contact_contactErrorMsg: "",
      }));
    }
  };

  const handleFocus = () => {
    if (!isLogin()) {
      history.push("/sign");
    }
  };

  const contactNumberLengthValidate = (e) => {
    const re = /^[0-9\b]+$/;
    if (!re.test(e.target.value) || e.target.value.length !== 10) {
      setState((prevState) => ({
        ...prevState,
        contact_contactError: true,
        contact_contactErrorMsg: "Contact number must be 10 digits.",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        contact_contactError: false,
        contact_contactErrorMsg: "",
      }));
    }
  };

  const initialValues = {
    name:
      getName() !== "" && getLastName() !== ""
        ? getName() + " " + getLastName()
        : "",
    email: getEmail(),
    contact: getContact(),
    topic: topic !== null || topic !== "" ? topic : "",
    message: "",
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          if (!state.contact_contactError) {
            onSubmitContactUsForm(values, actions);
          }
        }}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form
            id="contact-us-form"
            className="contactUsForm"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  id="filled-basic"
                  autoComplete="off"
                  variant="outlined"
                  type="text"
                  className="mt-2"
                  name="name"
                  fullWidth
                  label="Full Name"
                  value={values.name}
                  error={errors.name && touched.name}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                  required
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                />
                <FormHelperText
                  error
                  style={{ fontSize: "0.8rem", color: "red" }}
                >
                  {errors.name && touched.name ? errors.name : null}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type="text"
                  className="mt-2"
                  name="email"
                  fullWidth
                  label="Email"
                  value={values.email}
                  error={errors.email && touched.email}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required
                  style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                />
                <FormHelperText
                  error
                  style={{ fontSize: "0.8rem", color: "red" }}
                >
                  {errors.email && touched.email ? errors.email : null}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="filled-basic"
                  autoComplete="off"
                  variant="outlined"
                  type="text"
                  className="mt-2"
                  name="contact"
                  label="Contact Number"
                  fullWidth
                  required
                  value={values.contact}
                  error={
                    (errors.contact && touched.contact) ||
                    state.contact_contactError
                  }
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onInput={(e) => contactNumberValidate(e)}
                  onBlur={(e) => contactNumberLengthValidate(e)}
                  inputProps={{ maxLength: 10 }}
                  style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                />
                <FormHelperText
                  error
                  style={{ fontSize: "0.8rem", color: "red" }}
                >
                  {errors.contact && touched.contact
                    ? errors.contact
                    : state.contact_contactErrorMsg}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className="mt-2"
                  style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                  fullWidth
                  required
                  onFocus={handleFocus}
                >
                  <InputLabel
                    classes={{
                      asterisk: classes.asterisk,
                    }}
                  >
                    Topic
                  </InputLabel>
                  <Select
                    name="topic"
                    label="Topic"
                    value={values.topic}
                    error={errors.topic && touched.topic}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Trainings">Trainings</MenuItem>
                    <MenuItem value="Articles">Articles</MenuItem>
                    <MenuItem value="Govt Circulars/GR">
                      Govt Circulars/GR
                    </MenuItem>
                    <MenuItem value="Tr for Teacher">Tr for Teacher</MenuItem>
                    <MenuItem value="Contribute">Contribute</MenuItem>
                    <MenuItem value="About Website">About Website</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText
                  error
                  style={{ fontSize: "0.8rem", color: "red" }}
                >
                  {errors.topic && touched.topic ? errors.topic : null}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="mt-2"
                  autoComplete="off"
                  variant="outlined"
                  label="Message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  error={errors.message && touched.message}
                  multiline
                  fullWidth
                  required
                  onFocus={handleFocus}
                  style={{ fontSize: "0.9rem", background: "#f6f1f1" }}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                />
                <FormHelperText style={{ fontSize: "0.8rem", color: "red" }}>
                  {errors.message && touched.message ? errors.message : null}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  className="CheckButton text-center mt-3 mx-auto px-4"
                  disabled={state.isSubmitting}
                >
                  {state.isSubmitting ? (
                    <Spinner variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={state.showSnackbar === true}
        autoHideDuration={6000}
        onClose={snackBarClose}
      >
        <Alert
          onClose={snackBarClose}
          severity={state.severityColor}
          elevation={6}
          variant="filled"
        >
          {state.snackBarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);