import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { Container, Row, Col, Card } from "bootstrap-4-react";
// import { List } from "bootstrap-4-react";
import { Nav } from "bootstrap-4-react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import HelpUsForm from "./HelpUsForm";

import "../style/Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {  faFacebookF, faVimeoV , faTwitter , faPinterestP  } from '@fortawesome/free-brands-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import Simple from "./Simple";
import { apiURL } from "../utils";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { NavLink } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import footerLogo from "../images/footerlogo.png";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Collapse from "@material-ui/core/Collapse";
import navneetFooter from "../images/Navneet-Logo-Footer.png";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignupSchema = yup.object().shape({
  help_name: yup.string().required("This field is required."),
  help_contact: yup.string().required("This field is required."),
  help_email: yup
    .string()
    .email("email must be valid email")
    .required("This field is required."),
  help_message: yup.string().required("This field is required."),
});

const SubscribeSchema = yup.object().shape({
  subscribe_email: yup
    .string()
    .email("subscribe email must be a valid email")
    .required("This field is required."),
});

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh",
    borderRadius: "25px",
    maxWidth: "42.75rem",
  },
  listItem: {
    padding: "0", // Adjust the padding as needed
    color: "white",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    returnMessage: "",
    showSnackbar: false,
    snackBarMessage: "",
    severityColor: "",
    reload: false,
    open: false,
    help_contactError: false,
    help_contactErrorMsg: "",
    openArticle: false,
    openGr: false,
    help_name: null,
    help_email: null,
    help_contact: null,
    help_message: null,
    subscribe_email: null,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      help_name: null,
      help_email: null,
      help_contact: null,
      help_message: null,
      subscribe_email: null,
    }));
  }, []);

  const changeHandler = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // console.log(e.target.name +'===='+e.target.value);
  };

  const handleClick = () => {
    setState((prevState) => ({ ...prevState, open: !prevState.open }));
  };

  const handleArticleClick = () => {
    setState((prevState) => ({ ...prevState, openArticle: !prevState.openArticle }));
  };

  const handleGRClick = () => {
    setState((prevState) => ({ ...prevState, openGr: !prevState.openGr }));
  };

  const snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((prevState) => ({ ...prevState, showSnackbar: false }));
  };

  const { open, openArticle, openGr } = state;

  return (
    <div>
      <footer className="FooterBox">
        {/*<Simple />*/}
        <Container>
          <Row className="linkContainer">
            <Grid container spacing={3} className="">
              <Grid item xs={6} sm={6} lg={4} className="quickLinkGrid">
                <h4>Quick Links</h4>
                <List component="nav" className="quickLinks">
                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to="/about-us"
                    >
                      <ListItemText primary="About Us" />
                    </NavLink>
                  </ListItem>

                  <ListItem
                    onClick={handleClick}
                    className="listItem"
                    listitem
                  >
                    <ListItemText primary="Trainings" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/overview"
                        >
                          <ListItemText primary="Overview" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/upcoming-webinars"
                        >
                          <ListItemText primary="Upcoming Events" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/past-trainings"
                        >
                          <ListItemText primary="Past Trainings" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/past-webinars"
                        >
                          <ListItemText primary="Past Webinars" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/video"
                        >
                          <ListItemText primary="Videos" />
                        </NavLink>
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    onClick={handleArticleClick}
                    className="listItem"
                    listitem
                  >
                    <ListItemText primary="Articles" />
                    {openArticle ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openArticle} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/blogs"
                        >
                          <ListItemText primary="Blogs" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/academic-essays"
                        >
                          <ListItemText primary="Academic Essays" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/success-stories"
                        >
                          <ListItemText primary="Success Stories" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/interviews"
                        >
                          <ListItemText primary="Interviews" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/book-recommendations"
                        >
                          <ListItemText primary="Book Recommendations" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/self-development"
                        >
                          <ListItemText primary="Self Development" />
                        </NavLink>
                      </ListItem>
                      {/* <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/news"
                        >
                          <ListItemText primary="News" />
                        </NavLink>
                      </ListItem> */}
                    </List>
                  </Collapse>
                  <ListItem
                    onClick={handleGRClick}
                    className="listItem"
                    listitem
                  >
                    <ListItemText primary="Govt. Circular/GR" />
                    {openGr ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openGr} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/maharashtra-state-board"
                        >
                          <ListItemText primary="MH State Board" />
                        </NavLink>
                      </ListItem>
                      <ListItem className="listCollapseItem">
                        <NavLink
                          exact
                          activeClassName="active_class"
                          className="nav-link"
                          to="/central-board"
                        >
                          <ListItemText primary="Central Board" />
                        </NavLink>
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to="/faq"
                    >
                      <ListItemText primary="FAQ's" />
                    </NavLink>
                  </ListItem>
                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to="/tr-for-teacher"
                    >
                      <ListItemText primary="Tr for Teacher" />
                    </NavLink>
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={6} md={6} lg={4} className="helpGrid">
                <h4>Help</h4>
                <List component="nav" className="help">
                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to={{
                        pathname: "/contact-us",
                        state: { topic: "Contribute" },
                      }}
                    >
                      <ListItemText primary="Contribute" />
                    </NavLink>
                  </ListItem>

                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to="/sign"
                    >
                      <ListItemText primary="Sign In / Sign Up" />
                    </NavLink>
                  </ListItem>
                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to="/terms-and-conditions"
                    >
                      <ListItemText primary="Terms & Conditions" />
                    </NavLink>
                  </ListItem>
                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to="/contact-us"
                    >
                      <ListItemText primary="Contact Us" />
                    </NavLink>
                  </ListItem>
                  <ListItem className="listItem">
                    <NavLink
                      exact
                      activeClassName="active_class"
                      className="nav-link"
                      to="/privacy-policy"
                    >
                      <ListItemText primary="Privacy Policy" />
                    </NavLink>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} className="socialContainer">
                <h4>Connect With Us</h4>
                <div className="navneetLogo">
                  <div className="firstLogo">
                    <a href="http://www.navneet.com" target="_blank">
                      <img src={navneetFooter} className="img-fluid mt-3" />
                    </a>
                  </div>
                </div>
                <div className="SocialBox">
                  <IconButton className="facebook">
                    <a
                      href="https://www.facebook.com/Navneet.India/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </IconButton>

                  <IconButton className="twitter">
                    <a
                      href="https://x.com/navneet_edu?t=iveT3_H5bdOFNXsMCZl0tQ&s=09"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </IconButton>
                  <IconButton className="instagram">
                    <a
                      href="https://www.instagram.com/navneet.education?igsh=aHhlbnJuZmo4bzQw"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </IconButton>
                  <IconButton className="linkedIn">
                    <a
                      href="https://www.linkedin.com/company/navneet-education-ltd-/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </IconButton>
                </div>

                <div className="navneetLogo">
                  <div className="secondLogo">
                    <a
                      href="http://www.navneetfoundation.net"
                      target="_blank"
                    >
                      <img src={footerLogo} className="img-fluid mt-3" />
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Row>
        </Container>
      </footer>

      <footer className="FooterBottom">
        <Container>
          <Row>
            <Col col="sm-6">
              <p>©Navneet Education Limited 2024. All rights reserved.</p>
            </Col>
            <Col col="sm-6" className="text-sm-right text-left">
              <p>Made by Navneet Education Limited</p>
            </Col>
            <Col col="sm-12">
              <p>
                <small>
                  Disclaimer:The opinions expressed in the articles published
                  on this portal are the personal opinion of the respective
                  authors. The information and opinion appearing in these
                  articles do not reflect the views and/or representations of
                  Navneet Foundation (NF) and NF does not assume any
                  responsibility or liability for the same.
                </small>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      <Snackbar
        open={state.showSnackbar === true}
        autoHideDuration={6000}
        onClose={snackBarClose}
      >
        <Alert
          onClose={snackBarClose}
          severity={state.severityColor}
          elevation={6}
          variant="filled"
        >
          {state.snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Footer;