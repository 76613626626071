import React, { Component } from 'react';  
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/Glimpses.scss";
import {  Container,  Row, Col, Media, BImg, BH5, BH6 } from 'bootstrap-4-react';
import { Button, Grid, Snackbar, TextField, TextareaAutosize, Divider } from '@material-ui/core';
import Footer from '../components/Footer'; 
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';   
import Typography from '@material-ui/core/Typography';
import { apiURL, isLogin, getName, getEmail } from '../utils';   
import SanitizedHTML from 'react-sanitized-html';
import axios from 'axios'; 
import Moment from 'moment';
import Meta from '../meta/Meta';  
import CommentForm from './CommentForm';
import Comments from './Comments';

import { Formik, Form, Field  } from 'formik';
import * as yup from 'yup'; 
import Alert from '@material-ui/lab/Alert'; 
import Share from './Share';
import Like from './Like';
import { Helmet } from 'react-helmet';


const styles = theme => ({
   root: {
      maxWidth: "100%",  
   },
   media: {
    height: "30rem",
    '@media(max-width: 420px)  and (min-width:360px) ' : {
      height: '13rem'
    },
    '@media(max-width: 900px)  and (min-width:760px) ' : {
      height: '24rem'
    },
    '@media(max-width: 550px)  and (min-width:421px) ' : {
      height: '17rem'
    },
    '@media(max-width: 320px)  and (min-width:270px) ' : {
      height: '9rem'
    }, 
   },
   
});


const CommentFormSchema = yup.object().shape({
   name: yup.string().required("This field is required."), 
   email: yup
     .string()
     .email()
     .required("This field is required."),
   message: yup.string().required("This field is required."),
 }); 

class BlogInner extends Component {
   constructor(props) {
      super(props);
      this.state = { 
         blogData: [],
         noData: '',
         blogComments: [],
         name: null,
         email: null,
         message: null,
         showSnackbar: false,
         snackBarMessage: '',
         severityColor:'',
      }; 
      this.blogListData = this.blogListData.bind(this);
      this.returnCommentsForm = this.returnCommentsForm.bind(this);
   }
   componentDidMount() { 
      this._isMounted = true; 
      this.blogListData(); 
      this.blogComments(); 
      this.setState({
         name: null,
         email: null,
         message: null,
      })
  }

  componentWillUnmount() {
      this._isMounted = false; 
  }
 
  blogListData = () => {
      this.setState({ loading: true });
      const { per, page, faqData } = this.state; 
      const headers = { 
          'Content-type': 'application/json'
      }
      const body = { "id": this.props.match.params.blogId}
      axios.post(apiURL('blog/get_all_blog'), body, {headers: headers})
      .then(response => {
          
      if(this._isMounted){
          const resultData = response.data; 
          if(resultData.status === true || resultData.status === 1)
          { 
             //console.log(JSON.stringify(resultData.result[0].fldv_title))
              this.setState({  
               blogData: resultData.result[0], 
              });
              //this.setState({ loading: false  });
          }
          else{
              this.setState({noData: "Coming Soon"}); 
          }
      }
      })
  
      .catch(error => {
          console.log(error)
      }) 
  }

  returnCommentsForm = (e) => {
     this.componentDidMount(); 
  }

  
  blogComments = (values) => { 
   const headers = {
       'content-type': 'application/json',
       'Accept': 'application/json'
   }
   const body = { 
      "blog_id": this.props.match.params.blogId,
   }
   axios.post(apiURL('blog_comment/get_all_blog_comments'),body, {headers: headers})
   .then(response => {
       const resultData = response.data; 
       if(resultData.status === true || resultData.status === 1)
       { 
           this.setState({ blogComments: resultData.result }); 
       } 
   })
   .catch(error => {
       console.log(error)
   })  
}


submitBlogComment = (values) => { 
   const headers = {
       'content-type': 'application/json',
       'Accept': 'application/json'
   }
   const body = {
      "name": values.name,
      "email": values.email,
      "message": values.message,
      "blog_id": this.props.match.params.blogId,
   }
   axios.post(apiURL('blog_comment/add'),body, {headers: headers})
   .then(response => {
       const resultData = response.data; 
       if(resultData.status === true || resultData.status === 1)
       {
           this.setState({ snackBarMessage: resultData.message, showSnackbar: true, severityColor:'success' });
            
       }
       else{
         this.setState({ snackBarMessage: "Server error! Please Try after some time",showSnackbar: true , severityColor:'error'});
       } 
       this.setState({ name: '', email: '', message: ''});  
      // this.props.ReturnComments(true);
       this.componentDidMount();
   })
   .catch(error => {
       console.log(error)
   })  
}

snackBarClose = (event, reason) => {
if (reason === 'clickaway') {
    return;
  }
  this.setState({ showSnackbar: false }); 
}


 render() {  
   // const classes = styles();
   const { classes } = this.props;  
   return (
      <React.Fragment>
      <Meta tableName={"blog"} objectId={this.props.match.params.blogId} forPage={"inner_page"} title={this.state.blogData.fldv_title} imageSource={this.state.blogData.fldv_image_url}/>
      <Helmet>
         <meta property="og:title" content={this.state.blogData.fldv_title} />
         <meta property="og:image" content={this.state.blogData.fldv_image_url} />
         <meta property="og:type" content="blog" />
         <meta property="og:url" content={window.location.href} />
      </Helmet>
         <Appbar />
         <div className="sticky-top sign-light">
            <Navmenu />    
         </div>  
         <section className="BannerBox discussionBanner blogs">
            <div className="sixteen-nine">
               <div className="content text-center text-white invisible">
                  <h2>Blog</h2>
                  <p>Lorem ipsume</p>
                  
               </div>
            </div>
         </section>
         <section className="articleSingle">
            <Container>
               <Row>
                  <Col sm="12">
                  <Card className={classes.root}>
                        <CardMedia
                           className={classes.media}
                           image={this.state.blogData.fldv_image_url}
                           title="Contemplative Reptile"
                           />
                        <CardContent>
                           <Typography gutterBottom variant="h6" component="h6" className="d-none">
                              {Moment(this.state.blogData.fldv_selected_date).format('D MMMM, YYYY')}
                           </Typography>
                           <Typography gutterBottom variant="h4" component="h4" className="my-3">
                              {this.state.blogData.fldv_title}
                           </Typography>
                           <SanitizedHTML allowedAttributes={false} allowedTags={false} html={this.state.blogData.fldt_description} />

                           {isLogin()?<Like objectId={this.props.match.params.blogId} meta_title={'blog'} />:null}
                           <Share currentUrl={window.location.href} title={this.state.blogData.fldv_title} description={this.state.blogData.fldt_description} tableName={"blog"} objectId={this.props.match.params.blogId} forPage={"inner_page"} />
                        </CardContent>
                  </Card>
                   </Col>
               </Row>
            </Container>
         </section>
         {this.state.blogComments.length > 0? <section className="commentBox">
            <Container>
               <Row>
                  <Col sm="12">
                   <h3>{this.state.blogComments.length > 0 && this.state.blogComments.length+" Comment"}{this.state.blogComments.length > 1?"s":""}</h3> 
                  </Col>

                  {this.state.blogComments.map((arrList, index) => {
                      return <Col key={index} sm="12">
                        <Media className="mt-3"> 
                            <Media.Body>
                                <div className="d-flex flex-wrap">
                                <BH5>{arrList.fldv_name}</BH5>
                                <BH6> {Moment(arrList.flddt_created_date).format('Do MMM, YYYY')} at {Moment(arrList.flddt_created_date).format('hh:mm')} </BH6>
                               
                                </div>
                                <p>{arrList.fldt_message}</p>
                            </Media.Body>
                        </Media>
                       
                        <Divider />
                        </Col>  
                  })}
               </Row>
            </Container>
         </section>:""}
        
         

         <section className="commentBox">
            <Container>
               <Row>
                  <Col sm="12">
                  <h3>Leave A Comment</h3>
                  </Col>
                  <Col sm="5">
                  {/*<CommentForm BlogId={this.props.match.params.blogId} ReturnComments={(e) =>this.returnCommentsForm(e)} />*/}
                  <Formik
               initialValues={{
                  name:  isLogin()?getName():'', 
                  email: isLogin()?getEmail():'',
                  message: '',
               }}
               validationSchema={CommentFormSchema}
               onSubmit={(values, { resetForm }) => {  
                  this.submitBlogComment(values); 
                  //resetForm();  
                  resetForm({});
               }}
            >
            {({ errors, handleChange, touched }) => (
            <Form id="blog-comment" className="careerForm feedBackForm commentForm">
               <Grid container spacing={1}> 

                  {!isLogin()?<Grid item xs={12} sm={12} md={6}>
                     <TextField id="filled-basic"  value={this.state.name}  autocomplete="off"  variant="filled" type="text" className="mt-4" name="name" id="name"  label="Name" onChange={handleChange}  /> 
                     {/* error={errors.name && touched.name}  */}
                     <div className="w-100 small" style={{color:"red"}}> {errors.name && touched.name? errors.name: null} </div>
                  </Grid>:""}

                  {!isLogin()?<Grid item xs={12} sm={12} md={6}>
                     <TextField id="filled-basic" value={this.state.email} autocomplete="off"  variant="filled" type="text" className="mt-4" name="email" id="email" label="Email" onChange={handleChange} />
                     {/* error={errors.email && touched.email}  */}
                     <div className="w-100 small" style={{color:"red"}}>{errors.email && touched.email ? errors.email : null}  </div>
                  </Grid>:""}

                  <Grid item xs={12} sm={12}>
                     <TextareaAutosize rowsMax={4} value={this.state.message} className="w-100 mt-4 textAreaBox" aria-label="maximum height" placeholder="Message" name="message" id="message" onChange={handleChange} />
                     <div className="w-100 small" style={{color:"red"}}>  {errors.message && touched.message ? errors.message: null} </div>
                  </Grid> 

                  <Grid item xs={12} sm={12} > 
                     <Button type="submit" className="CheckButton text-center mt-2" style={{fontSize:"17px"}}> SEND MESSAGE </Button>
                  </Grid>

               </Grid>
               </Form>
               )}
            </Formik>
                  </Col>
               </Row>
            </Container>
         </section>

         
         <Footer />
         <Snackbar anchorOrigin={{  vertical: "top", horizontal: "center" }} open={this.state.showSnackbar === true} autoHideDuration={6000} onClose={this.snackBarClose}>
               <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
               { this.state.snackBarMessage }
               </Alert>
            </Snackbar>
      </React.Fragment>
      );
   }
}
export default withStyles(styles)(BlogInner);  