import React, { Fragment } from "react";
import UpcomingEvHome from "./UpcomingEvHome";
import PastTrainingHome from "./PastTrainingHome";
import PastWebinarHome from "./PastWebinarHome";

const HomeEvents = ({ history }) => {
  return (
    <Fragment>
      <UpcomingEvHome history={history} />
      <PastTrainingHome history={history} />
      <PastWebinarHome history={history} />
    </Fragment>
  );
};

export default HomeEvents;
