import React, { Component, createRef } from "react";
import "../../style/BookMark.scss";
import { Row, Col, Card } from "bootstrap-4-react";

import Button from "@material-ui/core/Button";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../../utils";
import axios from "axios";
import Moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import {
  Snackbar,
  IconButton,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Meta from "../../meta/Meta";
import { ArrowLeft, BookmarkBorder } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
// import logoNew from "../images/navdisha.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RegistrationForm from "../../webinar/RegistrationForm";
import { IoMdCheckmarkCircleOutline } from "@react-icons/all-files/io/IoMdCheckmarkCircleOutline";
import ArticleImageLoader from "../Article/ArticleImageLoader";
const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
});

class PastWebinarBookmark extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      title: "",
      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,
      selectedItem: 0,
      pastWeb: this.props.pastWeb,
      isMobile: false,
    };

    this.carouselRef = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pastWeb !== this.props.pastWeb) {
      this.setState({ pastWeb: this.props.pastWeb });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleReadMore = (e, id, title) => {
    e.preventDefault();

    this.props.history.push("/past-webinar-schedule/" + id + "/" + title);
  };

  handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedItem, per } = this.state;
    const { pastWeb, handleRemoveWebinarBookMark } = this.props;
    this.props.handleRemoveWebinarBookMark(id);
    const updatedEvents = pastWeb.filter((event) => event.fldi_id !== id);
    const totalPages = Math.ceil(updatedEvents.length / per);

    if (selectedItem >= totalPages && totalPages > 0) {
      this.setState({ selectedItem: totalPages - 1 });
    }
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.props.pastWeb.length / per);

    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };

  render() {
    const { classes, id } = this.props;
    const { selectedItem, per, pastWeb, isMobile } = this.state;

    const displayData = isMobile
      ? pastWeb.slice(selectedItem, selectedItem + 1)
      : pastWeb.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={11} forPage={"main_page"} />

        {displayData.length !== 0 && (
          <div>
            <Carousel
              ref={this.carouselRef}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={false}
              showIndicators={false}
              showStatus={false}
              selectedItem={selectedItem}
               className="bookmark-carousel"
              onChange={(index) => this.setState({ selectedItem: index })}
            >
              <Row className="pastWebBook position-relative">
                {" "}
                {displayData.map((arrList) => (
                  <Col
                    col="12 col-lg-6 col-xl-4"
                    className="mx-auto custom-col-spacing"
                  >
                    <Card>
                      <Card.Body
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          this.handleReadMore(
                            e,
                            arrList.fldi_id,
                            arrList.fldv_title.replace(/ /g, "-").toLowerCase()
                          )
                        }
                      >
                        <div className="position-relative">
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <div className="Speaker">
                                <figure>
                                  <div className="d-block imgBox">
                                    <ArticleImageLoader
                                       imageUrl={arrList?.fldv_image_url}
                                       alt="img"/>
                                  </div>
                                  <p>{arrList.fldv_speaker_name}</p>
                                </figure>
                              </div>
                            </Grid>
                            <Grid item xs={8}>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th colSpan="2" className="topic">
                                      {arrList.fldv_title}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>Date:</th>
                                    <td>
                                      {Moment(
                                        arrList.fldv_selected_date
                                      ).format("ddd, D MMM, YYYY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Time:</th>
                                    <td>
                                      {Moment(arrList.fldt_time).format("LT")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Duration:</th>
                                    <td>{arrList.fldv_duration} hrs</td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="action">
                                <div className="readmoreContainer">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    className="readmore"
                                    onClick={(e) =>
                                      this.handleReadMore(
                                        e,
                                        arrList.fldi_id,
                                        arrList.training_title
                                          .replace(/ /g, "-")
                                          .toLowerCase()
                                      )
                                    }
                                  >
                                    Read More
                                  </Button>
                                </div>
                                <div className="bookmarkContainer">
                                  <IconButton
                                    style={{ color: "orange" }}
                                    className="bookmark"
                                    onClick={(e) =>
                                      this.handleRemoveBookMark(
                                        e,
                                        arrList.fldi_id
                                      )
                                    }
                                  >
                                    <Bookmark />
                                  </IconButton>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel>
            <IconButton
              onClick={this.handlePrev}
              className={singleData ? "singlePrevButton" : "prevButton"}
            >
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              onClick={this.handleNext}
              className={singleData ? "singleNextButton" : "nextButton"}
            >
              <ArrowRightIcon />
            </IconButton>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PastWebinarBookmark);
