import React from "react";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { apiURL, getID, isLogin } from '../utils';   
import axios from 'axios'; 
 
class Like extends React.Component {
 
constructor(props){
    super(props);
    this.state = {
        liked: false
    }
    this.like = this.like.bind(this);
}

componentDidMount(){
    this.likedContent();
}

likedContent = () => { 
        const headers = {
            'content-type': 'application/json',
            'Accept': 'application/json'
        }
        const body = { 
            "meta_id": this.props.objectId,
            "meta_title": this.props.meta_title,
            "user_id": getID(),
            "content": 'Like',
        }
        axios.post(apiURL('like/liked'),body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({ liked: true }); 
            } 
        })   
}

like = (e) => {
    const headers = {
        'content-type': 'application/json',
        'Accept': 'application/json'
    }
    const body = { 
        "meta_id": this.props.objectId,
        "meta_title": this.props.meta_title,
        "user_id": isLogin()?getID():null,
        "content": 'Like',
    }
    axios.post(apiURL('like/add'),body, {headers: headers})
    .then(response => {
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
            this.setState({ liked: true }); 
        } 
    })
    .catch(error => {
        console.log(error)
    })  
    
}

  render () { 
    return (
      <React.Fragment>
        <div className="float-left"> 
          {this.state.liked?
            <FavoriteIcon style={{color:'#D42B28', cursor:'pointer', fontSize:'2.25rem'}} /> :
            <FavoriteBorderIcon style={{color:'#D42B28', cursor:'pointer', fontSize:'2.25rem'}} onClick={(e) => this.like(e)} />
        }
        </div>   
      </React.Fragment>
    );
  }
};
 
// export
export default Like;