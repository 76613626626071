import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import { IconButton, Button, Container } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../../utils";
import Meta from "../../meta/Meta";
import "../../style/BookMark.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Moment from "moment";
import ArticleImageLoader from "../Article/ArticleImageLoader";

const GovtCircularBookmark = (props) => {
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(window.innerWidth <= 1000 ? 1 : 2);
  const [categoryID, setCategoryID] = useState(null);
  const [noData, setNoData] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(0);
  const [circularData, setCircularData] = useState(props.circularData);
  const [isMobile, setIsMobile] = useState(false);

  // const carouselRef = useRef(null);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
    return () => {};
  }, []);

  useEffect(() => {
    if (props.circularData !== circularData) {
      setCircularData(props.circularData);
    }
  }, [props.circularData]);

  const redirectArticle = (e, id, title, category) => {
    if (!isLogin()) {
      props.history.replace("/sign");
    } else {
      props.history.push(
        "/news/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
  };

  const handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("circularData", circularData);
    const updatedEvents = circularData.filter((event) => event.news_id !== id);
    const totalPages = Math.ceil(updatedEvents.length / per);

    props.handleRemoveGRBookMark(id);

    if (selectedItem >= totalPages && totalPages > 0) {
      setSelectedItem(totalPages - 1);
    }
  };

  const handlePrev = () => {
    setSelectedItem((prevItem) => Math.max(prevItem - 1, 0));
  };

  const handleNext = () => {
    const totalPages = Math.ceil(props.circularData.length / per);
    setSelectedItem((prevItem) => Math.min(prevItem + 1, totalPages - 1));
  };

  const displayData = isMobile
    ? circularData.slice(selectedItem, selectedItem + 1)
    : circularData.slice(selectedItem * 2, selectedItem * 2 + 2);
  const singleData = displayData.length === 1;

  return (
    <React.Fragment>
      <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

      <div>
        {/* <section className="circularSection"> */}
        {/* <Container> */}
        <section className="circularGrSection">
          <Container maxWidth="lg" className="container">
            <h2>Govt. Circulars/GR</h2>
            <Carousel
              // ref={carouselRef}
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={false}
              showIndicators={false}
              showStatus={false}
              selectedItem={selectedItem}
               className="bookmark-carousel"
              onChange={(index) => setSelectedItem(index)}
            >
              <Row className="circularOtherBook">
                {displayData.map((arrNews, index) => (
                  <Col
                    key={index}
                    col="12 col-lg-6 col-xl-4"
                    className="mx-auto custom-col-spacing mt-4"
                  >
                    <Card
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        redirectArticle(
                          e,
                          arrNews.news_id,
                          arrNews.news_title,
                          props.category_name
                        )
                      }
                    >
                      <Card.Body>
                        <figure>
                          <div className="d-block mainImage">
                              <ArticleImageLoader
                               imageUrl={arrNews?.news_image_url}
                               alt={arrNews.news_title}/>
                          </div>
                        </figure>
                        <div className="content">
                          <div className="topic">
                            <h4>{arrNews.news_title}</h4>
                          </div>
                          <div className="date">
                            <h4>
                              {" "}
                              {Moment(arrNews.news_selected_date).format(
                                "D MMM, yyyy"
                              )}
                            </h4>
                          </div>
                        </div>
                      </Card.Body>

                      <div className="action">
                        <div  className="readmoreContainer">
                          <Button
                            variant="contained"
                            size="small"
                            className="readmore"
                            onClick={(e) =>
                              redirectArticle(
                                e,
                                arrNews.news_id,
                                arrNews.news_title
                              )
                            }
                          >
                            Read More
                          </Button>
                          <IconButton
                            style={{ color: "orange" }}
                            className="bookmark"
                            onClick={(e) =>
                              handleRemoveBookMark(e, arrNews.news_id)
                            }
                          >
                            <Bookmark />
                          </IconButton>
                        </div>
                        {/* <div>
                          <IconButton
                            style={{ color: "orange" }}
                            className="bookmark"
                            onClick={(e) =>
                              handleRemoveBookMark(e, arrNews.news_id)
                            }
                          >
                            <Bookmark />
                          </IconButton>
                        </div> */}
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel>
            <IconButton
              onClick={handlePrev}
              className={singleData ? "singlePrevButton" : "prevButton"}
            >
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              onClick={handleNext}
              className={singleData ? "singleNextButton" : "nextButton"}
            >
              <ArrowRightIcon />
            </IconButton>
          </Container>
        </section>
        {/* </Container>
        </section> */}
      </div>
    </React.Fragment>
  );
};

export default GovtCircularBookmark;
