import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import Home from './components/Home';
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import Loading from "./components/Loading";
import "../node_modules/react-bootstrap/dist/react-bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}
const isProduction = process.env.NODE_ENV === "production";
const { version } = packageInfo;

ReactDOM.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CacheBuster>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
