import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import { apiURL, getID } from '../utils';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
   root: {
      '& > *': {
         margin: theme.spacing(0),
         width: '100%',
      },
   },
});

const WebinarForm = (props) => {
   const [state, setState] = useState({
      checked: false,
      selected: null,
      selected1: null,
      hasError: false,
      stateList: [],
      feedback: '',
      showSnackbar: false,
      snackBarMessage: '',
      severityColor: '',
      snackBarTimer: ''
   });

   useEffect(() => {
      window.scrollTo(0, 0);
      return () => {
         // This will act as componentWillUnmount
      };
   }, []);

   const submitFeedback = useCallback((e) => {
      e.preventDefault();
      const headers = {
         'content-type': 'application/json',
         'Accept': 'application/json'
      }
      const body = {
         "webinar_id": props.webinarId,
         "user_id": getID(),
         "feedback": state.feedback
      }
      axios.post(apiURL('webinar_feedback/add'), body, { headers: headers })
         .then(response => {
            const resultData = response.data;
            if (resultData.status === true || resultData.status === 1) {
               setState(prevState => ({
                  ...prevState,
                  showSnackbar: true,
                  snackBarMessage: resultData.message,
                  severityColor: 'success',
                  snackBarTimer: '3000'
               }));
               props.formClose(true);
            }
            else {
               setState(prevState => ({
                  ...prevState,
                  showSnackbar: true,
                  snackBarMessage: resultData.message,
                  severityColor: 'error',
                  snackBarTimer: '3000'
               }));
            }
         })
         .catch(error => {
            console.log(error)
         })
   }, [props, state.feedback]);

   const handleChange = (e) => {
      setState(prevState => ({
         ...prevState,
         [e.target.name]: e.target.value
      }));
   };

   const snackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setState(prevState => ({ ...prevState, showSnackbar: false }));
   };

   const { classes } = props;
   const { selected, hasError, selected1 } = state;

   return (
      <React.Fragment>
         <form className="careerForm feedBackForm" onSubmit={(e) => submitFeedback(e)} noValidate autoComplete="off" >
            <Grid container>
               <Grid item xs={12} sm={12}>
                  <TextareaAutosize
                     rowsMax={4}
                     className="w-100 mt-4 textAreaBox"
                     aria-label="maximum height"
                     placeholder="Message"
                     defaultValue={state.feedback}
                     onChange={(e) => handleChange(e)}
                     name="feedback"
                  />
               </Grid>
               <Grid item xs={12} sm={12} >
                  <Button type="submit" className=" CheckButton text-center mt-3" >
                     SUBMIT
                  </Button>
               </Grid>
            </Grid>
         </form>
         <Snackbar open={state.showSnackbar === true} autoHideDuration={state.snackBarTimer} onClose={snackBarClose}>
            <Alert onClose={snackBarClose} severity={state.severityColor} elevation={6} variant="filled">
               {state.snackBarMessage}
            </Alert>
         </Snackbar>
      </React.Fragment>
   );
};

WebinarForm.propTypes = {
   classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WebinarForm);