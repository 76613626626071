import React, { useState } from "react";
import { Modal, Box, Typography, makeStyles, Button } from "@material-ui/core";
import image1 from "../images/Navdisha-Banner-600x600.jpg";
import { Close } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    padding: theme.spacing(2),
    maxWidth: "90vw",
    maxHeight: "90vh",
    width: "auto",
    height: "auto",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  button: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(1),
  },
}));

const PopUp = ({ open, handleClose }) => {
  //   const [open, setOpen] = useState(true);
  //   const handleClose = () => setOpen(false);
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modal}
    >
      <Box className={classes.paper}>
        <img src={image1} className={classes.image} />
        <Button className={classes.button} onClick={handleClose}>
          <Close />
        </Button>
      </Box>
    </Modal>
  );
};

export default PopUp;
