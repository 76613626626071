import React, { useState, useEffect, useRef } from "react";
import "../../style/BookMark.scss";
import { Row, Col, Card } from "bootstrap-4-react";

import Button from "@material-ui/core/Button";
import {
  Grid,
  IconButton,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../../utils";
import axios from "axios";
import Moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

import Meta from "../../meta/Meta";
import { ArrowLeft, BookmarkBorder } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RegistrationForm from "../../webinar/RegistrationForm";
import Certificate from "./Cetificate";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
});

function PastWebinarAchieve({ pastWeb, user }) {
  const [selectedItem, setSelectedItem] = useState(0);
  const [per, setPer] = useState(window.innerWidth <= 1000 ? 1 : 2);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [downloadTrigger, setDownloadTrigger] = useState(false);

  const [eventDetails, setEventDetails] = useState({
    title: "",
    date: "",
    time: "",
    duration: "",
    location: "",
  });

  const carouselRef = useRef(null);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
  }, []);

  useEffect(() => {
    setPer(window.innerWidth <= 1000 ? 1 : 2);
  }, [isMobile]);

  const handleDownload = (title, date, time, duration, location) => {
    setEventDetails({
      title,
      date,
      time: Moment(time).format("LT"),
      duration,
      location,
    });
    setDownloadTrigger(true);
  };
  const handleDownloadComplete = () => {
    setDownloadTrigger(false);
  };
  const handlePrev = (event) => {
    event.stopPropagation();
    setSelectedItem((prevSelectedItem) => Math.max(prevSelectedItem - 1, 0));
  };

  const handleNext = (event) => {
    event.stopPropagation();
    const totalPages = Math.ceil(pastWeb.length / per);
    setSelectedItem((prevSelectedItem) =>
      Math.min(prevSelectedItem + 1, totalPages - 1)
    );
  };

  const displayData = isMobile
    ? pastWeb.slice(selectedItem, selectedItem + 1)
    : pastWeb.slice(selectedItem * 2, selectedItem * 2 + 2);
  const singleData = displayData.length === 1;

  return (
    <React.Fragment>
      {console.log(pastWeb.slice(selectedItem, selectedItem + 1), selectedItem)}
      <Meta tableName={"menu"} objectId={11} forPage={"main_page"} />
      <div>
        <section className="pastWebAchieveSection">
          <Container maxWidth="lg" className="container">
            <h2>Past Webinar</h2>
            {displayData.length !== 0 && (
              <div>
                <Carousel
                  ref={carouselRef}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={false}
                  showIndicators={false}
                  showStatus={false}
                  selectedItem={selectedItem}
                  onChange={(index) => setSelectedItem(index)}
                >
                  <Row className="pastWebAchieve position-relative">
                    {displayData.map((arrList) => (
                      <Col
                        key={arrList.fldi_id}
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing"
                      >
                        <Card>
                          <Card.Body>
                            <div className="position-relative">
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <div className="Speaker">
                                    <figure>
                                      <div className="d-block imgBox">
                                        <img
                                          src={arrList.webinar_image_url}
                                          className="img-fluid"
                                          alt="img"
                                        />
                                      </div>
                                      <p>{arrList.webinar_speaker_name}</p>
                                    </figure>
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th colSpan="2" className="topic">
                                          {arrList.webinar_title}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th>Date:</th>
                                        <td>
                                          {Moment(
                                            arrList.webinar_changed_date
                                          ).format("ddd, D MMM, YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Time:</th>
                                        <td>
                                          {Moment(arrList.webinar_time).format(
                                            "LT"
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Duration:</th>
                                        <td>{arrList.webinar_duration} hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="actionWebAchieve">
                                    <div className="readmoreContainer">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        className="readmore"
                                        onClick={() =>
                                          handleDownload(
                                            arrList.webinar_title,
                                            arrList.webinar_changed_date,
                                            arrList.webinar_time,
                                            arrList.webinar_duration,
                                            arrList.webinar_venue_location
                                          )
                                        }
                                      >
                                        Download
                                      </Button>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Carousel>
                <IconButton
                  onClick={handlePrev}
                  className={singleData ? "singlePrevButton" : "prevButton"}
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  onClick={handleNext}
                  className={singleData ? "singleNextButton" : "nextButton"}
                >
                  <ArrowRightIcon />
                </IconButton>
              </div>
            )}
          </Container>
        </section>
        {downloadTrigger && (
          <div style={{ opacity: 0, position: "absolute", zIndex: -1 }}>
            <Certificate
              firstname={user.firstname}
              lastname={user.lastname}
              schoolName={user.schcollname}
              trainingTitle={eventDetails.title}
              date={eventDetails.date}
              time={eventDetails.time}
              duration={eventDetails.duration}
              location={eventDetails.location}
              eventType={"Webinar"}
              onDownloaded={handleDownloadComplete}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(PastWebinarAchieve);
