// WhatsNew.js
import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";

import "../style/WhatsNew.scss";
import ArticlesHome from "./ArticlesHome";
import GrCircularHome from "./GrCircularHome";
import { Row, Col, Card } from "bootstrap-4-react";
import NewsHome from "./NewsHome";
const WhatsNew = ({ history }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <section className="whatsnew">
      <h2 className="text-center">What's New</h2>
      <Row>
        <div className="buttons-container">
          <Button
            className={`button ${selectedTab === 0 ? "active" : ""}`}
            onClick={() => handleTabChange(0)}
          >
            Articles
          </Button>
          <Button
            className={`button ${selectedTab === 1 ? "active" : ""}`}
            onClick={() => handleTabChange(1)}
          >
            Govt. Circulars/GR
          </Button>
          {/* <Button
            className={`button ${selectedTab === 2 ? "active" : ""}`}
            onClick={() => handleTabChange(2)}
          >
            News
          </Button> */}
        </div>
      </Row>
      <Box mt={2}>
        {selectedTab === 0 && <ArticlesHome history={history} />}
        {selectedTab === 1 && <GrCircularHome history={history} />}
        {/* {selectedTab === 2 && <NewsHome history={history} />} */}
      </Box>
    </section>
  );
};

export default WhatsNew;
