import React, { Component, createRef } from "react";
import "../../style/BookMark.scss";
import { Row, Col, Card } from "bootstrap-4-react";

import Button from "@material-ui/core/Button";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Meta from "../../meta/Meta";

// import logoNew from "../images/navdisha.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import PastWebinarBookmark from "./PastWebinarBookmark";
import PastTrainingBookmark from "./PastTrainingBookmark";

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
});

class PastWebTrain extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      pastWeb: this.props.pastWeb,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ pastWeb: this.props.pastWeb });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, id } = this.props;
    const { pastWeb } = this.state;
    console.log(this.props);
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={11} forPage={"main_page"} />

        <div>
          <section className="pastWebTrainSection">
            <Container maxWidth="lg" className="container">
             <h2>Past Trainings & Past Webinar</h2>
              <PastWebinarBookmark
                pastWeb={this.props.pastWeb}
                history={this.props.history}
                handleRemoveWebinarBookMark={this.props.handleRemovePastWeb}
              />
              <PastTrainingBookmark
                pastTrain={this.props.pastTrain}
                history={this.props.history}
                handleRemovePastTrain={this.props.handleRemovePastTrain}
              />
            </Container>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PastWebTrain);
