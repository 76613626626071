import React,  {Component} from 'react';   
import { withStyles } from "@material-ui/core/styles"; 
import "../style/Discussion.scss";    
import { Table, TableContainer, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'; 
import { List } from 'bootstrap-4-react'; 
import { apiURL, getID } from '../utils'; 
import axios from 'axios';   
import Moment from 'moment'; 
import SanitizedHTML from 'react-sanitized-html';
import { Link, Redirect, useHistory, withRouter  } from 'react-router-dom';


const styles = theme => ({
    bigIndicator: {
    height: 0,
    },
 });

 class SuggestedTopics extends Component { 
    //state = { value: 0 }
  categoryID = this.props.categoryID;
    _isMounted = false; 
    constructor(props){
       super(props);
       this.state = {  
          categoryID: null,
          per_page: 3, 
          page: 1,
          loading: true,
          suggestedTopics: [],
       }
       
    }
 
    componentDidMount()
    {
       this._isMounted = true; 
       //this.getSuggestedTopics();
    }
    
    componentWillUnmount() {
       this._isMounted = false; 
    }
 
    componentDidUpdate (prevProps)
    {    
      if(this.state.loading){
         this.getSuggestedTopics();
      } 
      if(this.props.forumId !== prevProps.forumId ) {
         //alert('change hua'); 
         this.getSuggestedTopics();
      };
    }
 
    getSuggestedTopics = (categoryID) => {  
      const headers = {
        'Content-type': 'application/json'
      }
      const body = {
            "forum_id": this.props.forumId,
            "category_id": this.props.categoryID,
            "per_page": this.state.per_page,
            "page": this.state.page
         }
      axios.post(apiURL('forum/get_susggested_topics'), body, {headers: headers})
      .then(response => {
          const resultData = response.data;
          if(resultData.status === true)
          {
            this.setState({ 
                  loading:false,
                  suggestedTopics: resultData.result
              }); 
          }
          else{ 
            this.setState({forumValues: [], loading:false,});
          }
      })
      .catch(error => {
          console.log(error)
      })
    }
  
    
    render() { 
        return (
            <React.Fragment>  
               <h4 className="font-weight-bold">Suggested Topics</h4>
               <div className="table-responsive">
                  <TableContainer>
                     <Table className="simpleTable">
                        <TableHead>
                           <TableRow>
                              <TableCell  className="w-50">Topic</TableCell>
                              <TableCell> Category</TableCell>
                              <TableCell> Users</TableCell>
                              <TableCell> Replies</TableCell>
                              {/*<TableCell> Views</TableCell>
                              <TableCell> Activity</TableCell>*/}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {this.state.suggestedTopics.map((arrList, index) => {
                           return <TableRow key={index}>
                              <TableCell  > 
                                 <Link to={'/thread-answer/'+arrList.fldi_id+'/'+arrList.fldv_title.replace(/ /g, '-')}><SanitizedHTML html={arrList.fldv_content} /></Link>
                                    {arrList.tags.map((arrTags, index) => {
                                       return <span className="badge" key={arrTags.fldv_title}>{arrTags.fldv_title}</span>
                                    })}
                              </TableCell>
                              <TableCell>{arrList.fldv_category}</TableCell>
                              <TableCell>
                                 <List inline className="mb-0">
                                 <List.Item inline>{arrList.author_name.charAt(0)}</List.Item>
                                    {/*<List.Item inline>a</List.Item>
                                    <List.Item inline>b</List.Item>
                                    <List.Item inline>c</List.Item>*/}
                                 </List>
                              </TableCell>
                              <TableCell>{arrList.fldi_total_answers} </TableCell>
                              {/*<TableCell> 15.1k</TableCell>
                              <TableCell> 11d</TableCell>*/}
                           </TableRow>
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </div>
            </React.Fragment>
        );

    }
    
}


export default withStyles(styles)(SuggestedTopics);  