import React, { useState, useEffect } from "react";
import { Row, Container } from "bootstrap-4-react";
import Navmenu from "../Navmenu";
import Footer from "../Footer";
import "../../style/Glimpses.scss";
import { withStyles } from "@material-ui/core/styles";
import Appbar from "../Appbar";
import Meta from "../../meta/Meta";
import ArticleMain from "./ArticleMain";
import { ArrowDownward, ArrowDropDown, Translate } from "@material-ui/icons";
import { FaLanguage } from "@react-icons/all-files/fa/FaLanguage";

const styles = (theme) => ({
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    padding: "10px 30px 10px 10px",
    borderColor: "#064e89",
    borderWidth: "2px",
    borderRadius: "4px",
    appearance: "none",
    backgroundColor: "white",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#064e89",
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#064e89",
  },
  label: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "white",
    padding: "0 5px",
    color: "#064e89",
    fontWeight: "bold",
    transition: "0.2s",
    pointerEvents: "none",
    transformOrigin: "top left",
    "&.float": {
      top: "-10px",
      transform: "scale(0.75)",
    },
  },
});

const SuccessStories = (props) => {
  const [language, setLanguage] = useState("");
  const { classes, history } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", signNav);

    return () => {
      window.removeEventListener("scroll", signNav);
    };
  }, []);

  const signNav = () => {
    // Implement signNav logic if needed
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);
    // Add logic to filter articles based on selected language
  };

  const labelClass = `${classes.label} ${language ? "float" : ""}`;

  return (
    <React.Fragment>
      <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

      <div>
        <Appbar />
        <div className="sticky-top sign-light">
          <Navmenu />
        </div>
        <section className="BannerBox articleBanner">
          <div className="sixteen-nine">
            <div className="content text-center text-white d-none">
              <h2>Articles </h2>
              <p>Update</p>
            </div>
          </div>
        </section>

        <section className="mainSection">
          <Container>
            <h1 className="text-center">Success Stories</h1>
            <div className="filter">
              <div className="languageArticleFilter">
                <div className="formControlArticle">
                  <label htmlFor="language-select" className={labelClass}>
                    <FaLanguage className="langSvg" />
                    <span> Language</span>
                  </label>
                  <select
                    id="language-select"
                    value={language}
                    onChange={handleChange}
                    className="selectArticle"
                    onFocus={(e) => e.target.classList.add("focused")}
                    onBlur={(e) => e.target.classList.remove("focused")}
                  >
                    <option value="">All</option>
                    <option value="English">English</option>
                    <option value="Marathi">Marathi</option>
                  </select>
                  <ArrowDropDown className={classes.icon} />
                </div>
              </div>
            </div>

            <ArticleMain
              category_id={2}
              language={language}
              history={history}
              category_name={"Success Stories"}
            />
          </Container>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(SuccessStories);
