import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  FormHelperText,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import { Row, Col } from "bootstrap-4-react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { getID, apiURL } from "../../utils";
import axios from "axios";
import * as yup from "yup";
import EmailVerified from "../../login/EmailVerified";
import { Fragment } from "react";
import { CloseOutlined } from "@material-ui/icons";
import { Spinner } from "react-bootstrap";
const profileSchema = yup.object().shape({
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  role: yup.string().required("This field is required."),
  city: yup.string().required("This field is required."),
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#00aad3",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,

  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const boardAndMedium = [
  "SSC-Marathi",
  "SSC-English",
  "SSC-Hindi",
  "SSC-Urdu",
  "CBSE",
  "ICSE",
  "Others",
];
const options = [
  "Do not Teach",
  "Pre-Primary (Nursery- Senior Kg)",
  "Primary (Grade 1 to Grade 4)",
  "Middle School (Grade 5 to Grade 7)",
  "High School (Grade 8 to Grade 10)",
  "Higher Secondary (Grade 11 to Grade 12)",
  "Others",
];
const subjectList = [
  "Do not Teach",
  "All Subjects",
  "English",
  "Marathi",
  "Hindi",
  "Urdu",
  "Mathematics",
  "Science",
  "Social Sciences",
  "Commerce Faculty",
  "Arts Faculty",
  "Science Faculty",
  "Others",
];
const EditForm = ({ userData, updateUserData, onCancelClick }) => {
  const [cityList, setCityList] = useState([]);
  const [authEmail, setAuthEmail] = useState("");
  const [otpBoxOpen, setOtpBoxOpen] = useState(false);
  const [isEmailAdresssVerified, setEmailAddressVerified] = useState(false);
  const [sendOtpString, setSendOtpString] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailVerifiedMsg, setIsEmailVerifiedMsg] = useState("");
  const [grades, setSelected] = useState(userData.grades || []);
  const [subjects, setSubject] = useState(userData.subjects || []);
  const [board, setBoard] = useState(userData.board || "");
  const emailVerified = localStorage.getItem("isEmailVerified");
  const [open, setOpen] = React.useState(false);
  const [openSubjectDrop, setSubjectDrop] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log(userData);
  useEffect(() => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(apiURL("common/get_city/" + 12), { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setCityList(resultData.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const check_email = (e) => {
    if (e.target.value) {
      if (
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          e.target.value
        )
      ) {
        const headers = {
          "content-type": "application/json",
          Accept: "application/json",
        };
        const body = { email: e.target.value };
        axios
          .post(apiURL("register/check_email"), body, { headers: headers })
          .then((response) => {
            const resultData = response.data;
            console.log(resultData.message.includes("Domain"));
            if (resultData.status === true || resultData.status === 1) {
              setOtpBoxOpen(false);
              setEmailError(true);
              setEmailErrorMsg(resultData.message);
            } else {
              setEmailError(false);
              setEmailErrorMsg("");
            }
          });
      } else {
        setOtpBoxOpen(false);
        setEmailError(true);
        setEmailErrorMsg("Please Enter Valid Email Address");
      }
    }
  };
  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };
  const handleTextKeyPress = (event) => {
    const { name, value } = event.target;

    const regex = /^[a-zA-Z\s]*$/;

    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const sendOTP = (e, isCancelButtonClicked) => {
    if (isCancelButtonClicked) {
      // If cancel button is clicked, do nothing
      return;
    }
    const inputValue = e.target.value.trim();

    if (
      !isEmailAdresssVerified &&
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(inputValue) &&
      !emailError &&
      authEmail.length === 0
    ) {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        email: inputValue,
        //"number": this.state.mobileNumber,
      };
      axios
        .post(apiURL("register/send_otp_email"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            setAuthEmail(atob(decodeURIComponent(resultData.result)));
            setOtpBoxOpen(true);
            setSendOtpString(false);
            setEmail(document.getElementById("email").value);

            localStorage.setItem("otp", resultData.result);
          }
        });
    } else if (email.length > 0) {
      setOtpBoxOpen(false);
      setSendOtpString(false);
      setEmail(document.getElementById("email").value);
    } else {
      setSendOtpString(false);
    }
  };
  const otpBoxClose = (event) => {
    if (event === true) {
      setAuthEmail("");
      setOtpBoxOpen(false);
      setEmailAddressVerified(true);
      setSendOtpString(false);
      setIsEmailVerified(false);
      setIsEmailVerifiedMsg("");
    }
  };

  const onClickOTPClose = (event) => {
    if (event === true) {
      setOtpBoxOpen(false);
      setEmailAddressVerified(false);
      setIsEmailVerified(true);
      setIsEmailVerifiedMsg("Please verify email address");
    }
  };
  const changeNumber = (event) => {
    if (event === true) {
      setAuthEmail("");
      setOtpBoxOpen(false);
      setEmailAddressVerified(false);
      setIsEmailVerified(false);
      setIsEmailVerifiedMsg("");
    }
  };

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    const filteredSubjects = subjects.filter((subject) =>
      subjectList.includes(subject)
    );
    if (emailError || isEmailVerified) {
      return;
    } else if (
      values.email !== "" &&
      emailVerified === null &&
      !isEmailAdresssVerified
    ) {
      return;
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        fldi_id: getID(),
        first_name: values.firstname,
        last_name: values.lastname,
        status: values.role,
        email: values.email,
        city: values.city,
        sch_col: values.schcollname,
        pincode: values.pincode,
        board_medium: board,
        grades_taught: grades,
        subjects_taught: filteredSubjects,
      };
      axios
        .post(apiURL("teacher/edit_profile"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;

          if (resultData.status === true || resultData.status === 1) {
            updateUserData({
              ...values,
              grades: grades,
              subjects: filteredSubjects,
              board: board,
            });
            setIsSubmitting(false);
            localStorage.setItem(
              "user_detail",
              JSON.stringify(resultData.result)
            );
          } else {
            this.setState({
              snackBarMessage: resultData.message,
              showSnackbar: true,
              severityColor: "error",
            });
            setIsSubmitting(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
        });
    }

    onCancelClick();
  };
  const handleClose = () => {
    const filteredGrades = grades.filter((grade) => grade !== undefined);
    setSelected(filteredGrades);
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);
  const handleSubjectDropOpen = () => {
    setSubjectDrop(true);
  };

  const handleSubjectDropClose = () => {
    const filteredSubject = subjects.filter((sub) => sub !== undefined);
    setSubject(filteredSubject);
    setSubjectDrop(false);
  };
  const handleBoardChange = (e) => {
    setBoard(e.target.value);
  };
  const handleDropChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSelected(options.filter((option) => option !== "all"));
    } else {
      const filteredValue = value.filter((val) => val !== undefined);
      setSelected(filteredValue);
    }
  };
  const handleSubjectDropChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSubject(options.filter((option) => option !== "all"));
    } else {
      const filteredValue = value.filter((val) => val !== undefined);
      setSubject(filteredValue);
    }
  };
  const classes = useStyles();
  return (
    <Fragment>
      <Formik
        initialValues={userData}
        validationSchema={profileSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <Row className="editProfile">
              <Col col="12 col-lg-6 col-xl-4">
                <div className="form-row">
                  <Typography className="label">
                    Full Name<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="input-field"
                        name="firstname"
                        placeholder="Your First Name"
                        value={values.firstname}
                        fullWidth
                        onChange={handleChange}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        onKeyPress={handleTextKeyPress}
                        error={errors.firstname && touched.firstname}
                        helperText={
                          errors.firstname && touched.firstname
                            ? errors.firstname
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="lastname"
                        placeholder="Your Last Name"
                        className="input-field"
                        value={values.lastname}
                        onChange={handleChange}
                        onKeyPress={handleTextKeyPress}
                        fullWidth
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        error={errors.lastname && touched.lastname}
                        helperText={
                          errors.lastname && touched.lastname
                            ? errors.lastname
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className="form-row">
                  <Typography className="label">
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    name="contact"
                    placeholder="Your Contact Number"
                    className="input-field"
                    value={values.contact}
                    onChange={handleChange}
                    fullWidth
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    disabled
                  />
                </div>
                <div className="form-row">
                  <Typography className="label">
                    City<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    select
                    className="input-field"
                    name="city"
                    placeholder="Your City"
                    fullWidth
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={values.city}
                    onChange={handleChange}
                  >
                    {cityList.map((arrCity, index) => {
                      return (
                        <MenuItem key={arrCity.id} value={arrCity.city}>
                          {arrCity.city}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="form-row">
                  <Typography className="label">
                    Role<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    select
                    name="role"
                    placeholder="Your Role"
                    className="input-field"
                    InputProps={{ disableUnderline: true }}
                    value={values.role}
                    onChange={handleChange}
                    fullWidth
                    variant="filled"
                  >
                    <MenuItem value="Principal">Principal</MenuItem>
                    <MenuItem value="Teacher">Teacher</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </TextField>
                </div>
                <div className="form-row">
                  <Typography className="label">School Name</Typography>
                  <TextField
                    name="schcollname"
                    className="input-field"
                    placeholder="Your School/College Name"
                    value={values.schcollname}
                    onChange={handleChange}
                    fullWidth
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                </div>
              </Col>
              <Col col="12 col-lg-6 col-xl-4">
                <div className="form-row">
                  <Typography className="label">Pincode</Typography>
                  <TextField
                    name="pincode"
                    className="input-field"
                    placeholder="Your Pincode"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={values.pincode}
                    onChange={handleChange}
                    fullWidth
                    onKeyPress={handleKeyPress}
                    inputProps={{ maxLength: 6, inputMode: "numeric" }}
                  />
                </div>

                <div className="form-row">
                  <Typography className="label">Board & Medium</Typography>
                  <TextField
                    select
                    className="input-field"
                    name="board"
                    placeholder="Your Board & Medium"
                    fullWidth
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={board}
                    onChange={handleBoardChange}
                    renderValue={(selected) => {
                      if (boardAndMedium.includes(selected)) {
                        return selected;
                      } else {
                        return "";
                      }
                    }}
                  >
                    {boardAndMedium.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="form-row">
                  <Typography className="label">
                    Grades Taught/Managed
                  </Typography>
                  <Select
                    className="select-field"
                    name="grades"
                    placeholder="Your Grades Taught/Managed"
                    InputProps={{ disableUnderline: true }}
                    multiple
                    value={grades}
                    onChange={handleDropChange}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    variant="filled"
                    renderValue={(grades) => {
                      if (grades.length === 0) {
                        return "None";
                      }
                      return grades.join(", ");
                    }}
                    MenuProps={MenuProps}
                    fullWidth
                  >
                    <MenuItem
                      onClick={handleClose}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        style={{ marginTop: "0.1rem" }}
                        color="info"
                        size="small"
                      >
                        <CloseOutlined />
                      </Button>
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            style={{ color: "#00aad3" }}
                            checked={grades.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>

                  {/* <FormHelperText>
              You can select more than one option.
            </FormHelperText> */}
                </div>
                <div className="form-row">
                  <Typography className="label">Subject Taught</Typography>

                  <Select
                    className="select-field"
                    labelId="multi-select-label"
                    id="subjects"
                    name="subjects"
                    placeholder="Your Subject Taught"
                    fullWidth
                    multiple
                    variant="filled"
                    open={openSubjectDrop}
                    value={subjects}
                    onChange={handleSubjectDropChange}
                    onOpen={handleSubjectDropOpen}
                    onClose={handleSubjectDropClose}
                    renderValue={(subjects) => {
                      if (subjects.length === 0) {
                        return "";
                      }
                      const validSubjects = subjects.filter((subject) =>
                        subjectList.includes(subject)
                      );
                      return validSubjects.join(", ");
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      onClick={handleSubjectDropClose}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        style={{ marginTop: "0.1rem" }}
                        color="info"
                        size="small"
                      >
                        <CloseOutlined />
                      </Button>
                    </MenuItem>
                    {subjectList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            style={{ color: "#00aad3" }}
                            checked={subjects.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="form-row">
                  <Typography className="label">Email Address</Typography>
                  <TextField
                    className="input-field"
                    id="email"
                    disabled={emailVerified === null ? false : true}
                    fullWidth
                    variant="filled"
                    placeholder="Your Email Address"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={(e) => {
                      check_email(e);
                      sendOTP(e);
                      // emailValid(e);
                    }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <Button
                          disabled={authEmail.length === 0}
                          style={{
                            fontSize: 8,
                          }}
                          onClick={() => {
                            setOtpBoxOpen(true);
                            setSendOtpString(false);
                          }}
                        >
                          Enter Otp
                        </Button>
                      ),
                    }}
                    onKeyUp={(e) => check_email(e)}
                    error={
                      (errors.email && touched.email) ||
                      emailError ||
                      isEmailVerified
                    }
                    helperText={
                      errors.number && touched.number
                        ? errors.number
                        : null || emailErrorMsg || isEmailVerifiedMsg
                    }
                  />
                </div>
              </Col>
              <div className="button-container">
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner variant="light" /> : "Update"}
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
      <EmailVerified
        otpBoxOpen={otpBoxOpen}
        otpValue={authEmail}
        onClickOTPClose={(e) => onClickOTPClose(e)}
        otpBoxClose={(e) => otpBoxClose(e)}
        email={email}
        changeNumber={(e) => changeNumber(e)}
      />
    </Fragment>
  );
};

export default EditForm;
